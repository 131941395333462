import { observable, action, computed, runInAction } from 'mobx'
import Parse from 'parse'
import { Category } from './aggregate/Category'
import CategoryNewForm from './forms/CategoryNewForm'
import CategoryEditForm from './forms/CategoryEditForm'
import CategoryImportForm from './forms/CategoryImportForm'
import CategoryWidgetForm from './forms/CategoryWidgetForm'
import { ListWidgetVM } from './view-models/ListWidgetVM'
import { RootStore } from '../stores/RootStore'
import CategoriesImportVM from './view-models/CategoriesImportVM'
import { DataStore } from '../shared/data/DataStore'
import { ICategoryDTO } from './dtos/ICategoryDTO'
import { CategoriesRelationsService } from './services/CategoriesRelationsService'
import * as CategoriesRelationsServiceWorker from './services/CategoriesRelationsService.worker'
import { ParseService } from '../services/ParseService'

export class CategoriesStore extends DataStore<Category, ICategoryDTO> {
  private relWorker: typeof CategoriesRelationsServiceWorker

  constructor(rootStore: RootStore) {
    super(rootStore, Category, 'categories', [
      'organizationId',
      'parentCategoryId',
      'name',
      'description',
      'availableForSkills',
      'availableForSurveys',
      'availableForTasks',
      'availableForWork',
      'isDeleted',
    ])
  }

  @observable public selectedCategoryId: string
  @observable public showImportDialog: boolean = false
  @observable public newForm: CategoryNewForm = null
  @observable public editForm: CategoryEditForm = null
  @observable public importVM: CategoriesImportVM = null
  @observable public importForm: CategoryImportForm = null
  @observable public textFilter: string = ''
  @observable public widgetForm: CategoryWidgetForm = new CategoryWidgetForm()
  @observable public editIconShown: boolean = false
  @observable public showImportModal: boolean = false
  @observable public loaded: boolean = false
  @observable public listWidgetVM: ListWidgetVM = null

  @action
  public onListRecordsLoaded() {
    this.listWidgetVM = new ListWidgetVM(this.rootStore)
    this.computeRelationsOnCategories()
    this.loaded = true
  }

  private computeRelationsOnCategories() {
    this.currentOrgCategories.forEach((e) => {
      e.computeRelations(this.records)
      this.loadChildren(e)
    })
    setTimeout(
      () =>
        this.currentOrgCategories.forEach((e) => {
          this.loadChildren(e)
        }),
      2000
    )
  }

  private loadChildren(category: Category) {
    let useWorker: boolean = false
    if (process.env.NODE_ENV === 'test') useWorker = false
    if (this.isIE()) useWorker = false
    if (!useWorker) {
      const svc = new CategoriesRelationsService()
      category.setChildren(svc.getChildren(category.objectId, this.records.slice()))
      return
    }
    this.loadRelationsWorker()
    category.setChildren(this.relWorker.getChildren(category.objectId, this.records.slice()))
  }

  private loadRelationsWorker() {
    if (this.relWorker) return
    this.relWorker = (
      CategoriesRelationsServiceWorker as any
    )() as typeof CategoriesRelationsServiceWorker
  }

  @computed
  public get categories() {
    return this.records
  }

  @action
  public setSelectedCategory(id) {
    this.selectedCategoryId = id
    this.editForm = new CategoryEditForm(this.rootStore, this.getCategory(id))
    this.newForm = null
  }

  @action
  public startNewCategory() {
    this.selectedCategoryId = null
    this.editForm = null
    this.newForm = new CategoryNewForm(this.rootStore)
  }

  @action
  public startNewCategoryFile() {
    this.importForm = new CategoryImportForm(this.rootStore)
  }

  @action clearForms() {
    this.importForm = null
    this.editForm = null
    this.newForm = null
    this.selectedCategoryId = null
  }

  @action
  public reloadEditVM(id) {
    this.widgetForm.setField('objectId', id)
    this.widgetForm.initEditForm(this.records, id)
  }

  @action
  public hideEditButton() {
    this.editIconShown = false
  }

  @action
  public showEditButton() {
    this.editIconShown = true
  }

  @action toggleImportDialog = () => {
    this.showImportDialog = !this.showImportDialog
  }

  @action
  public setImportCategories() {
    this.selectedCategoryId = null
    this.showImportDialog = true
    this.importForm = new CategoryImportForm(this.rootStore)
  }

  @action
  public toggleImportModal() {
    this.showImportModal = !this.showImportModal
    if (this.showImportModal) this.importVM = new CategoriesImportVM(this.rootStore)
    else this.importVM = null
  }

  @computed
  public get currentOrgCategories() {
    let list = this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
    list = list.sort((a, b) => {
      if (a.pathName < b.pathName) {
        return -1
      }
      if (a.pathName > b.pathName) {
        return 1
      }
      return 0
    })
    if (this.textFilter !== '') {
      list = list.filter(
        (e) => e.pathName.toLowerCase().indexOf(this.textFilter.toLowerCase()) !== -1
      )
    }
    return list
  }

  @computed
  public get currentTreeData(): any[] {
    let roots = []
    let nodes = {}
    const arry = this.currentOrgCategories
    for (let i = 0, len = arry.length; i < len; ++i) {
      let item = arry[i],
        p = item.parentCategoryId,
        target = !p ? roots : nodes[p] || (nodes[p] = [])
      target.push({
        key: item.objectId,
        label: item.name,
        id: item.objectId,
        value: item.objectId,
      })
    }
    let findChildren = function (parent) {
      if (nodes[parent.key]) {
        parent.nodes = nodes[parent.key]
        for (let i = 0, len = parent.nodes.length; i < len; ++i) {
          findChildren(parent.nodes[i])
        }
      }
    }
    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i])
    }
    return roots
  }

  @computed
  public get selectOptions() {
    let categories = this.currentOrgCategories
    let currentCategoryId = this.selectedCategoryId
    let options = []
    for (let category of categories) {
      if (category.objectId !== currentCategoryId) {
        options.push({
          value: category.objectId,
          label: category.name,
          parent: category.parentCategoryId ? true : false,
        })
      }
    }
    options.push({
      value: '',
      label: '- No Parent -',
      parent: false,
    })
    let currentCategory = this.getCategory(currentCategoryId)
    if (!currentCategory) return options
    let findChildren = function (parentId) {
      const foundChildren = categories.filter((e) => e.parentCategoryId === parentId)
      if (foundChildren) {
        for (let child of foundChildren) {
          options = options.filter((e) => e.value !== child.objectId)
          findChildren(child.objectId)
        }
        return options
      }
    }
    if (currentCategory) {
      options = findChildren(currentCategory.objectId)
      return options
    }
  }

  @action
  public setTextFilter(val) {
    this.textFilter = val
  }

  public getCategory(objectId): Category {
    return this.records.find((e) => e.objectId === objectId)
  }

  public getCategoryByName(name): Category {
    return this.records.find((e) => e.name === name)
  }

  private getCategoryIndex(objectId): number {
    return this.records.findIndex((e) => e.objectId === objectId)
  }

  public deleteCategory(objectId) {
    this.records.splice(this.getCategoryIndex(objectId), 1)
  }

  public async saveWidgetForm() {
    this.widgetForm.setSavePending()
    if (!this.widgetForm.isNameValid) return
    //Format data for Parse save
    const data = this.widgetForm.toJsonObject()
    const svc = new ParseService()
    const savedData = await svc.saveCategory(this.rootStore.appStore.currentOrgId, data)
    //Update UI state
    this.widgetForm.setSaveSuccessful()
    this.widgetForm.setSaveResolved()
    this.computeRelationsOnCategories()
    //Update MobX state with returned save object
    // this.loadCategories() //<-----not working
    setTimeout(() => this.widgetForm.closeDrawer(), 1000)
    if (this.listWidgetVM.categoryTreeVM) this.listWidgetVM.categoryTreeVM.buildTreeData()
  }
}
