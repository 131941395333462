import React from 'react'
import { Grid } from '@material-ui/core'
import { observer, useLocalStore } from 'mobx-react'
import './ActionableInsightsHomeScreen.scss'
import { FaBolt, FaSun, FaCloudRain } from 'react-icons/fa'
import { RiBardFill } from 'react-icons/ri'
import { InsightFrameVM } from '../view-models/InsightFrameVM'
import { InsightVM } from '../view-models/InsightVM'

interface Props {
  frame: string
  insights: InsightVM[]
}

const ActionableInsightsFrameCard: React.FC<Props> = ({ frame, insights }) => {
  const localStore = useLocalStore(() => ({
    vm: new InsightFrameVM(insights, frame),
  }))
  const vm = localStore.vm
  if (!vm) return

  const renderCategorySentimentScoreIcon = (score: number) => {
    if (!score) return null
    if (score <= 33) return <FaBolt className='category-sentiment-score-icon red-lightning' />
    if (score > 33 && score < 68)
      return <FaCloudRain className='category-sentiment-score-icon yellow' />
    return <FaSun className='category-sentiment-score-icon green-sun' />
  }

  const renderFramingInsights = (hasInsights: boolean, insights: string[]) => {
    if (!hasInsights)
      return (
        <p className='insight-text body'>No actionable insights are available for this period.</p>
      )
    return (
      <>
        {insights.map((insight, i) => (
          <div className='insight-data-item' key={i}>
            <RiBardFill className='insight-text-icon' />
            <p className='insight-text body'>{insight}</p>
          </div>
        ))}
      </>
    )
  }

  return (
    <div>
      <Grid
        container
        direction='row'
        alignItems='flex-start'
        justifyContent='space-between'
        className='framing-container'
      >
        <Grid item xs={4} style={{ height: '172px' }}>
          <div className='framing-inner-wrapper'>
            <div className='category-text-container'>
              <h4 className='category-name h3-sm'>{vm.title}</h4>
              {vm.hasFramingInsights && (
                <p className='category-description support-text'>{vm.framingDirective}</p>
              )}
            </div>
            <div className='category-sentiment-score-container'>
              {renderCategorySentimentScoreIcon(vm.framingScore)}
              <span className='category-sentiment-score callout'>{vm.framingScore}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          {renderFramingInsights(vm.hasFramingInsights, vm.framingInsights)}
        </Grid>
      </Grid>
    </div>
  )
}

export default observer(ActionableInsightsFrameCard)
