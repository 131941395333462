import axios from 'axios'
import env from '../../../../env'
import { IActionableInsightsPeriodsRequest } from '../interfaces/IActionableInsightsPeriodsRequest'
import { IActionableInsightsPeriodsResponse } from '../interfaces/IActionableInsightsPeriodsResponse'
import { IActionableInsightsGroupsRequest } from '../interfaces/IActionableInsightsGroupsRequest'
import { IActionableInsightsGroupsResponse } from '../interfaces/IActionableInsightsGroupsResponse'
import { IActionableInsightsInsightsRequest } from '../interfaces/IActionableInsightsInsightsRequest'
import { IActionableInsightsInsightsResponse } from '../interfaces/IActionableInsightsInsightsResponse'
import { IActionableInsightsQuickStatsRequest } from '../interfaces/IActionableInsightsQuickStatsRequest'
import { IActionableInsightsQuickStatsResponse } from '../interfaces/IActionableInsightsQuickStatsResponse'

export class ActionableInsightsService {
  private getApiUrl(): string {
    return env.var.REACT_APP_API_URL
  }

  public async getPeriods(
    req: IActionableInsightsPeriodsRequest,
    token: string
  ): Promise<IActionableInsightsPeriodsResponse> {
    const url = this.getApiUrl() + '/actionable-insights/periods'
    const body = { req, sessionToken: token }
    try {
      const response = await axios.post(url, body)
      return response.data.data
    } catch (error) {
      console.error('Failed to fetch actionable insights periods', error)
      throw error
    }
  }

  public async getGroups(
    req: IActionableInsightsGroupsRequest,
    token: string
  ): Promise<IActionableInsightsGroupsResponse> {
    const url = this.getApiUrl() + '/actionable-insights/groups'
    const body = { req, sessionToken: token }
    try {
      const response = await axios.post(url, body)
      return response.data.data
    } catch (error) {
      console.error('Failed to fetch actionable insights groups', error)
      throw error
    }
  }

  public async getQuickStats(
    req: IActionableInsightsQuickStatsRequest,
    token: string
  ): Promise<IActionableInsightsQuickStatsResponse> {
    const url = this.getApiUrl() + '/actionable-insights/quick-stats'
    const body = { req, sessionToken: token }
    try {
      const response = await axios.post(url, body)
      return response.data.data
    } catch (error) {
      console.error('Failed to fetch actionable insights quick stats', error)
      throw error
    }
  }

  public async getInsights(
    req: IActionableInsightsInsightsRequest,
    token: string
  ): Promise<IActionableInsightsInsightsResponse> {
    const url = this.getApiUrl() + '/actionable-insights/insights'
    const body = { req, sessionToken: token }
    try {
      const response = await axios.post(url, body)
      return response.data.data
    } catch (error) {
      console.error('Failed to fetch insights', error)
      throw error
    }
  }
}
