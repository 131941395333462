import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../upload/aggregate/Attachment'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import { PulseCampaignsService } from '../service/PulseCampaignsService'
import { PulseCampaignSurveyEditVM } from './PulseCampaignSurveyEditVM'
import { PulseCampaignEditTabsEnum } from '../type/PulseCampaignEditTabsEnum'
import { PulseCampaignEditSteppersVM } from './PulseCampaignEditSteppersVM'
import { PulseCampaignStatus } from '../type/PulseCampaignStatus'
import { IPulseCampaignSaveRequest } from '../interfaces/IPulseCampaignSaveRequest'
import { IPulseCampaignDeleteRequest } from '../interfaces/IPulseCampaignDeleteRequest'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { PulseCampaignSurvey } from '../aggregate/PulseCampaignSurvey'
import moment from 'moment-timezone'
import { WeekdayVM } from './WeekdayVM'
import { Days } from '../../surveys/aggregate/Days'
import { DateUtils } from '../../shared/data/DateUtils'
import { DateEditVM } from './DateEditVM'
import _ from 'lodash'
import { TimeZoneType } from '../../surveys/types/TimeZoneType'
import HashId from '../../shared/HashId'
import { IPulseCampaignDTO } from '../dto/IPulseCampaignDTO'
import { IParticipantDTO } from '../../surveys/dtos/IParticipantDTO'
import { IPulseQuestionsFindRequest } from '../../pulse-questions/interfaces/IPulseQuestionsFindRequest'
import { PulseQuestionsDataStore } from '../../pulse-questions/view-models/PulseQuestionsDataStore'
import * as Sentry from '@sentry/browser'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { PulseCampaignTemplatesVM } from './PulseCampaignTemplatesVM'
import { PLUGINS, TOOLBAR } from '../../config/TinyMCEconfig'
import { EmailTemplatePreviewVM } from '../../email-templates/view-models/EmailTemplatePreviewVM'
import { EmailTemplate } from '../../email-templates/aggregate/EmailTemplate'
import { ReminderVM } from '../../surveys/view-models/ReminderVM'
import { AutoCloseVM } from './AutoCloseVM'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { SurveyCompletedContentVM } from './SurveyCompletedContentVM'
import PulseCampaignType from '../../pulse-campaign-types/aggregate/PulseCampaignType'
import { SurveyTemplatesService } from '../../survey-templates/services/SurveyTemplatesService'
import { IOrganizationUserDTO } from '../../organization-users/dtos/IOrganizationUserDTO'

export class PulseCampaignEditVM {
  private rootStore: RootStore
  private svc: PulseCampaignsService
  private templateSvc: SurveyTemplatesService
  private reactions: IReactionDisposer[] = []
  private MAX_PULSE_SURVEYS: number = 60

  constructor(
    rootStore: RootStore,
    pulseCampaign: PulseCampaign,
    isTemplate: boolean,
    surveyId?: string
  ) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaign
    this.ls = this.rootStore.labelsStore
    this.svc = new PulseCampaignsService()

    this.loadAvailablePulseCampaignOwners()

    this.manageVM = new PulseCategoriesManageVM(rootStore, false, this)
    this.buildVM = new PulseCategoriesManageVM(rootStore, true)
    this.templatesVM = new PulseCampaignTemplatesVM(rootStore)
    this.surveyCompletedContentVM = new SurveyCompletedContentVM(this.rootStore, this)

    const canSurveyClients =
      this.rootStore && this.rootStore.appStore && this.rootStore.appStore.canSurveyClients

    this.participantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false,
      true,
      false
    )

    this.steppersVM = new PulseCampaignEditSteppersVM(rootStore, this)

    this.previewVM = new EmailTemplatePreviewVM(rootStore)
    if (surveyId) {
      this.currentTabIndex = 2
      const newIndex = pulseCampaign.surveys.findIndex((e) => e.id === surveyId)
      if (newIndex) setTimeout(() => this.setCurrentSurveyIndex(newIndex), 500)
    }

    this.loadData(pulseCampaign, isTemplate)
  }

  public handleEdit(emailTemplate: EmailTemplate) {
    if (!emailTemplate) return
    this.previewVM.loadData(emailTemplate, false, false)
  }

  @observable public pulseCampaign: PulseCampaign
  @observable public participantsSelectVM: ParticipantsSelectVM
  @observable public saveDraftTried: boolean = false
  @observable public publishTried: boolean = false
  @observable public applyTried: boolean = false
  @observable public sidePanelShown: boolean = false
  @observable public cleanPulseCampaign: PulseCampaign = null
  @observable public isIntroScreenDesignerOpen: boolean = false
  @observable public isCompletedContentScreenDesignerOpen: boolean = false
  @observable public showUploadModal: boolean = false
  @observable public currentSurveyIndex: number = -1
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public isSaving: boolean = false
  @observable public isLoading: boolean = false
  @observable public currentTabIndex: number = 0
  @observable public isDirty: boolean = false
  @observable public steppersVM: PulseCampaignEditSteppersVM = null
  @observable public pulseCampaignWasJustPublished: boolean = false
  @observable public confirmSaveDialogOpen: boolean = false
  @observable public doInitiate: boolean = false
  @observable public campaignStartDate: Date
  @observable public isCampaignStartDateValid: boolean = true
  @observable public startTime: Date
  @observable public days: Days = new Days()
  @observable public newIndex: number = 0
  @observable public oldIndex: number = 0
  @observable public lz = undefined
  @observable public ls = undefined
  @observable public showDeleteCampaignDialog = false
  @observable public showCloseCampaignDialog = false
  @observable public isTemplateUpdate: boolean = false
  public vmCreatedAt: Date = new Date()

  @observable public manageVM: PulseCategoriesManageVM = null
  @observable public buildVM: PulseCategoriesManageVM = null
  @observable public templatesVM: PulseCampaignTemplatesVM = null
  @observable public previewVM: EmailTemplatePreviewVM = undefined
  @observable body: string = ''
  @observable public isPreview: boolean = false
  @observable public isAutoSaveDraft: boolean = false
  @observable public autoSaveInt: any = null
  @observable public previewTakeVM: UserSurveyTakeVM = null
  @observable public selectedOptionValue: string = 'campaign-survey-published-EMAIL-en'
  @observable public cleanPublishedTemplates: EmailTemplate[] = []
  @observable public cleanReminderTemplates: EmailTemplate[] = []
  @observable public surveyCompletedContentVM: SurveyCompletedContentVM = null
  @observable public surveyCompletedContent: string = ''
  @observable public ownerDialogOpen: boolean = false
  @observable public tempOwnerUserId: string = null
  @observable public isTemplateBuildMode: boolean = true
  @observable public isSimplePreviewMode: boolean = false
  @observable public availableOwners: IOrganizationUserDTO[] = []

  @action
  public loadData(pulseCampaign: PulseCampaign, isTemplate: boolean) {
    this.isLoading = true
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())

    this.pulseCampaign = pulseCampaign
    this.pulseCampaign.isTemplate = isTemplate
    if (this.isPreview) {
      this.pulseCampaign.organizationId = this.rootStore.appStore.currentOrgId
      this.pulseCampaign.ownerUserId = this.rootStore.appStore.currentUserId
    }
    this.cleanPulseCampaign = pulseCampaign.clone()

    this.participantsSelectVM.setPillsPerRow(2)
    this.participantsSelectVM.setParticipants(pulseCampaign.participants)

    if (this.pulseCampaign.surveys.length === 0) this.createNewSurveyForCurrentCampaign()
    this.setCurrentSurveyIndex(0)

    if (this.pulseCampaign.startDate) {
      this.campaignStartDate = moment(this.pulseCampaign.startDate).toDate()
    } else {
      this.campaignStartDate = DateUtils.getBrowserDate(
        this.pulseCampaign.schedule.startDate,
        this.pulseCampaign.schedule.deliveryTimeZone
      )
    }

    if (this.pulseCampaign.schedule && this.pulseCampaign.schedule.startDate) {
      this.startTime = DateUtils.getBrowserDate(
        this.pulseCampaign.schedule.startDate,
        this.pulseCampaign.schedule.deliveryTimeZone
      )
    } else if (this.pulseCampaign.startDate) {
      this.startTime = moment(this.pulseCampaign.startDate).toDate()
    } else {
      this.startTime = this.roundTo15Min()
      this.pulseCampaign.setStartDate(this.startTime)
    }
    this.setOwnerUserId()
    this.loadSelectedQuestions(pulseCampaign.pulseQuestionIds)
    this.setTempOwnerUser(pulseCampaign.ownerUserId)
    this.reactions.push(
      when(
        () => this.rootStore.emailTemplatesStore.loaded && this.rootStore.notificationsStore.loaded,
        () => {
          if (this.currentTemplate && this.notificationTemplate) {
            this.cleanPublishedTemplates =
              this.currentSurvey.pulseCampaignSurvey.publishedTemplates.map((t) =>
                EmailTemplate.create(t)
              )
            this.cleanReminderTemplates =
              this.currentSurvey.pulseCampaignSurvey.reminderTemplates.map((t) =>
                EmailTemplate.create(t)
              )
            this.loadCurrentSurveyTakePreview()
            this.currentTemplate.body = this.notificationTemplate.body
            const intro = this.replaceMarkupForPreview(this.currentTemplate)

            this.currentTemplate.html.replace('{{{body}}}', intro)
            this.handleEdit(this.currentTemplate)
          }
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.selectedOptionValue),
        () => {
          if (this.currentTemplate && this.notificationTemplate) {
            this.loadCurrentSurveyTakePreview()
            this.currentTemplate.body = this.notificationTemplate.clone().body

            const intro = this.replaceMarkupAndKeywordsForPreview(
              this.currentTemplate,
              this.currentSurvey.surveyIntroduction
            )

            this.previewVM.setEmailTemplate(this.currentTemplate)
            const htmlPreview = this.currentTemplate.html.replace('{{{body}}}', intro)
            this.previewVM.setPreviewHtml(htmlPreview)
          }
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.pulseCampaign),
        () => {
          this.deepEqual(this.cleanPulseCampaign, this.pulseCampaign)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => this.isDirty,
        () => {
          if (this.isDirty === true) {
            this.autoSaveValidation()
          }
        }
      )
    )
  }

  private async loadAvailablePulseCampaignOwners() {
    if (!this.rootStore.appStore.currentOrgId) {
      setTimeout(() => this.loadAvailablePulseCampaignOwners(), 1000)
      return
    }
    const result = await this.svc.getAvailablePulseCampaignOwners(
      this.rootStore.appStore.currentOrgId
    )
    runInAction(() => (this.availableOwners = result))
  }

  @action
  public toggleSimplePreviewMode() {
    this.isSimplePreviewMode = !this.isSimplePreviewMode
  }

  @action
  public goBack() {
    this.rootStore.appStore.router.push('/pulse/v1/campaigns')
  }

  @action
  public resetStepper() {
    this.steppersVM = new PulseCampaignEditSteppersVM(this.rootStore, this)
    this.currentTabIndex = 0
  }

  @action
  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public toggleDeleteCampaignDialog() {
    this.showDeleteCampaignDialog = !this.showDeleteCampaignDialog
  }

  @action
  public toggleCloseCampaignDialog() {
    this.showCloseCampaignDialog = !this.showCloseCampaignDialog
  }

  @action
  public addIntroImage(attachment: Attachment) {
    if (!this.currentSurvey) return
    this.currentSurvey.setAttachment(attachment)
  }

  @action
  public setCurrentSurveyIndex(idx: number) {
    this.currentSurveyIndex = idx
  }

  @action
  public setName(val) {
    this.pulseCampaign.setName(val)
  }

  @action
  public setSurveyName(index, val) {
    this.surveys[index].setName(val)
    if (this.autoCloseEnabled) this.setCloseDates()
  }

  @action
  public setOwnerUserId() {
    if (this.pulseCampaign.ownerUserId) return
    if (!this.rootStore.appStore.currentUserId) return
    this.pulseCampaign.ownerUserId = this.rootStore.appStore.currentUserId
  }

  @computed
  public get objectId(): string {
    return this.pulseCampaign.objectId
  }

  @computed
  public get pulseCampaignTypeId(): string {
    return this.pulseCampaign.pulseCampaignTypeId
  }

  @computed
  public get pulseCampaignType(): PulseCampaignType {
    return this.rootStore.pulseCampaignTypesStore.getPulseCampaignType(this.pulseCampaignTypeId)
  }

  @computed
  public get pulseCampaignTypes(): PulseCampaignType[] {
    if (!this.rootStore.pulseCampaignTypesStore.pulseCampaignTypes.length) return []
    return this.rootStore.pulseCampaignTypesStore.pulseCampaignTypes
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .filter((x) => x.isActive)
  }

  @action
  public setPulseCampaignType(id: string) {
    this.pulseCampaign.setPulseCampaignTypeId(id)
  }

  @computed
  public get publishedByUserId(): string {
    return this.pulseCampaign.publishedByUserId
  }

  @computed
  public get name(): string {
    return this.pulseCampaign.name
  }

  @computed
  public get isTemplate(): boolean {
    return this.pulseCampaign.isTemplate
  }

  @computed
  public get isClosed(): boolean {
    return this.pulseCampaign.isClosed
  }

  @computed
  public get isPublished(): boolean {
    if (this.isTemplate && this.objectId && !this.pulseCampaign.name.includes('[Auto-saved Draft]'))
      return true
    if (this.pulseCampaign.status === 'published') return true
    return false
  }

  @computed
  public get isDeleted(): boolean {
    return this.pulseCampaign.isDeleted
  }

  @computed
  public get nameValid() {
    return this.name && this.name.trim() !== ''
  }

  @computed
  public get scheduleValid() {
    if (!this.publishTried) return true
    // TODO actually validate that all start dates are before the end dates for each survey.
    return true
  }

  @computed
  public get isParticipantsValid() {
    if (!this.publishTried) return true
    if (
      this.participantsSelectVM.participants.length === 0 &&
      this.participantsSelectVM.anonymousParticipants.length === 0
    )
      return false
    return true
  }

  @computed
  public get areSurveysValid() {
    if (!this.saveDraftTried) return true
    let notValidQuestions = this.surveys.filter((e) => !e.allValid)
    if (notValidQuestions.length) return false
    return true
  }

  @action
  public unsetIsTemplateBuildMode() {
    this.isTemplateBuildMode = false
  }

  @computed
  public get tabBuildMode(): string {
    if (!this.isPreview) {
      if (this.isTemplateBuildMode && !this.pulseHasQuestions) {
        return 'templates'
      } else {
        return 'questions'
      }
    } else {
      if (this.hasSelectedSurveyTemplate) {
        if (this.isTemplateBuildMode) {
          return 'templates'
        } else if (!this.isTemplateBuildMode && this.hasSelectedSurveyTemplate) {
          return 'templates'
        } else {
          return 'questions'
        }
      } else {
        if (this.isTemplateBuildMode && this.currentSurvey.isSurveyTemplate) {
          return 'templates'
        } else if (this.isTemplateBuildMode && !this.currentSurvey.isSurveyTemplate) {
          return 'questions'
        } else {
          return 'templates'
        }
      }
    }
  }

  @action
  public toggleCampaignBuildMode() {
    this.isTemplateBuildMode = !this.isTemplateBuildMode
  }

  @computed
  public get campaignStatus(): PulseCampaignStatus {
    return this.pulseCampaign.status
  }

  @action
  public capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  @computed
  public get campaignStatusText(): string {
    if (!this.isTemplate && this.campaignStatus === 'draft') return this.lz.not_yet_initiated
    return this.capitalize(this.pulseCampaign.status)
  }

  @computed
  public get currentSurvey(): PulseCampaignSurveyEditVM {
    if (this.surveys.length === 0) return undefined
    const currentSurvey = this.surveys[this.currentSurveyIndex]
    currentSurvey.loadSurveyTemplate()
    return currentSurvey
  }

  @computed
  public get surveys(): Array<PulseCampaignSurveyEditVM> {
    return this.pulseCampaign.surveys
      .filter((e) => !e.isDeleted)
      .map((s) => new PulseCampaignSurveyEditVM(this.rootStore, this, s, this.isTemplate))
  }

  @computed
  public get numberOfSurveys(): number {
    return this.pulseCampaign.surveys.length
  }

  @computed
  public get isSystemAdmin() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.user) return false
    return this.rootStore.userStore.user.isSystemAdmin
  }

  @computed
  public get isStartDateValid(): boolean {
    if (!this.publishTried) return true
    if (!this.campaignStartDate) return false
    return true
  }

  public getPreviouslyUsedInPulses(pulseQuestionId: string): number[] {
    return this.surveys.reduce(
      (surveyIds: number[], currentSurvey: PulseCampaignSurveyEditVM, index: number) => {
        const foundQuestion = currentSurvey.questions.find(
          (currentQuestion) => currentQuestion.originalPulseQuestionId === pulseQuestionId
        )
        if (!foundQuestion) return surveyIds
        return [...surveyIds, index + 1]
      },
      []
    )
  }

  private getSurveyIndex(id: string): number {
    return this.pulseCampaign.surveys.findIndex((e) => e.id === id)
  }

  @action
  public createNewSurveyForCurrentCampaign() {
    const survey = PulseCampaignSurvey.create(this.rootStore.appStore.currentOrgId, this.isTemplate)
    if (this.surveys.length === 0) this.setCurrentSurveyIndex(0)
    this.addSurveyToCurrentCampaign(survey)
  }

  @action
  public addSurveyToCurrentCampaign(survey: PulseCampaignSurvey) {
    this.pulseCampaign.surveys.push(survey)
    this.resetDates(false)
  }

  @action
  public copySurveyToCurrentCampaign(survey: PulseCampaignSurvey) {
    const copiedSurvey: PulseCampaignSurvey = survey.clone()
    copiedSurvey.setId(HashId.generate())
    copiedSurvey.setName(copiedSurvey.name + ` (${this.lz.copy})`)
    copiedSurvey.setStatus('scheduled')
    this.addSurveyToCurrentCampaign(copiedSurvey)
  }

  @action
  public deleteSurveyFromCurrentCampaign(id: string) {
    const ndx = this.getSurveyIndex(id)
    if (ndx !== -1) this.pulseCampaign.surveys.splice(ndx, 1)
  }

  @action
  public clearAllSurveys() {
    this.pulseCampaign.surveys = []
  }

  @action
  public async loadCurrentSurveyTakePreview() {
    const svc = new PulseCampaignsService()
    const templateSvc = new SurveyTemplatesService(this.rootStore)
    const campaign = this.toDTO()
    const campaignSurvey = campaign.surveys.find((s) => s.id === this.currentSurvey.id)
    let survey
    if (campaignSurvey.surveyTemplateId) {
      const template = await templateSvc.getSurveyTemplate(
        campaignSurvey.surveyTemplateId,
        this.rootStore.appStore.currentOrgId
      )
      survey = await svc.getConvertedSurveyFromTemplate(campaign, template)
    } else {
      survey = await svc.getConvertedCampaignSurvey(campaign, campaignSurvey)
    }
    survey.surveyIntroduction = this.currentSurvey.surveyIntroduction
    survey.questionsFlowFormat = 'paged'
    this.previewTakeVM = new UserSurveyTakeVM(
      this.rootStore,
      survey as UserSurvey,
      'previewer',
      true
    )
  }

  @action
  public loadPreviewer() {
    const currentSurveyIntro = this.currentSurvey ? this.currentSurvey.surveyIntroduction : ''
    if (this.currentSurvey.pulseCampaignSurvey.publishedTemplates.length === 0)
      this.currentSurvey.pulseCampaignSurvey.publishedTemplates = this.templates
    this.cleanPublishedTemplates = this.currentSurvey.pulseCampaignSurvey.publishedTemplates.map(
      (t) => EmailTemplate.create(t)
    )
    this.cleanReminderTemplates = this.currentSurvey.pulseCampaignSurvey.reminderTemplates.map(
      (t) => EmailTemplate.create(t)
    )

    this.loadCurrentSurveyTakePreview()

    const intro = this.replaceMarkupAndKeywordsForPreview(
      this.previewVM.emailTemplate,
      currentSurveyIntro
    )
    this.currentSurvey.setOriginalSurveyIntroduction(currentSurveyIntro)
    this.currentSurvey.setSurveyIntroduction(currentSurveyIntro)

    this.setBody(intro)

    const htmlPreview = this.replaceKeywordsForPreview(this.body, this.previewVM.emailTemplate)
    this.previewVM.setPreviewHtml(htmlPreview)
  }

  @action
  public previewTemplateSurvey(currentIndex) {
    this.setCurrentSurveyIndex(currentIndex)
    this.loadPreviewer()
    this.currentSurvey.toggleSurveyPreview()
  }

  @action
  public previewSurvey() {
    this.loadPreviewer()
    this.currentSurvey.toggleSurveyPreview()
  }

  @action
  public openIntroScreenDialog() {
    this.loadPreviewer()
    this.applyTried = false
    this.isIntroScreenDesignerOpen = true
  }

  @action
  public closeIntroScreenDialog() {
    this.isIntroScreenDesignerOpen = false
    this.applyTried = false
  }

  @action
  public openCompletedContentScreenDialog() {
    this.loadPreviewer()
    this.applyTried = false
    this.isCompletedContentScreenDesignerOpen = true
  }

  @action
  public closeCompletedContentScreenDialog() {
    this.isCompletedContentScreenDesignerOpen = false
    this.applyTried = false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public navigateToPulseCampaigns() {
    this.rootStore.appStore.router.push('/pulse/v1/campaigns')
    this.rootStore.pulseCampaignsStore.viewModels.loadPulseCampaignEditVM('empty')
  }

  @computed
  public get isNewPulseCampaign(): boolean {
    if (!this.pulseCampaign) return true
    if (this.pulseCampaign.objectId) return false
    return true
  }

  @computed
  public get showCopyPulseCampaignButton() {
    return !this.isNewPulseCampaign
  }

  @computed
  public get showClosePulseCampaignButton() {
    return !this.isNewPulseCampaign && this.pulseCampaign.status === 'initiated'
  }

  @computed
  public get shouldShowTopMenu() {
    if (this.isPreview) return false
    return !this.isNewPulseCampaign
  }

  @computed
  public get templateIsEditable(): boolean {
    if (!this.objectId) return false
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.pulseCampaign.templateOnlyEditableByOwner) return true
    return this.rootStore.appStore.currentUserId === this.pulseCampaign.publishedByUserId
  }

  @computed
  public get isTemplateAndDisabled(): boolean {
    if (this.isTemplate && this.objectId && !this.templateIsEditable) return true
    return false
  }

  @computed
  public get isReadOnly(): boolean {
    if (this.pulseCampaign.status === 'completed') return true
    return false
  }

  @computed
  public get showCopySurveyButton() {
    return !this.isNewPulseCampaign
  }

  @computed
  public get isBuild() {
    return this.currentTabIndex === PulseCampaignEditTabsEnum.BUILD
  }

  @action
  public setCurrentTab(idx) {
    if (!this.pulseCampaign.questions.length && idx === 2) {
      if (this.isTemplate) return this.buildTemplateWarnings()
    }
    if (idx === PulseCampaignEditTabsEnum.SCHEDULE) {
      if (this.autoCloseEnabled) this.setCloseDates()
    }
    if (this.isNewPulseCampaign) this.steppersVM.updateSteppers(idx, this.currentTabIndex)
    if (idx === PulseCampaignEditTabsEnum.INITIATE) return
    this.currentTabIndex = idx
    if (this.currentTabIndex === PulseCampaignEditTabsEnum.AUDIENCE) {
      this.participantsSelectVM.setVisible()
    } else {
      this.participantsSelectVM.setHidden()
    }
  }

  @action
  public goBackToPreviousTab() {
    let DETAILS
    if (this.isPreview) {
      if (
        this.currentTabIndex > PulseCampaignEditTabsEnum.TEMPLATES &&
        this.currentTabIndex < PulseCampaignEditTabsEnum.INITIATE
      ) {
        this.setCurrentTab(this.currentTabIndex - 1)
      } else {
        this.setCurrentTab(this.currentTabIndex - 2)
      }
    } else {
      DETAILS = 0
      if (
        this.currentTabIndex > DETAILS &&
        this.currentTabIndex < PulseCampaignEditTabsEnum.INITIATE
      ) {
        this.setCurrentTab(this.currentTabIndex - 1)
      } else {
        this.setCurrentTab(this.currentTabIndex - 2)
      }
    }
  }

  private buildTemplateWarnings = () => {
    if (!this.isTemplate) return

    if (this.templatesVM.hasTemplates) {
      this.openSnackbar(`${this.lz.error}: ${this.lz.please_select_template}`)
    } else {
      if (this.canManage) {
        this.openSnackbar(`${this.lz.error}: ${this.lz.please_create_template}`)
      } else {
        this.openSnackbar(`${this.lz.error}: ${this.lz.please_contact_admin}`)
      }
    }
  }

  @action
  public goToNextTab() {
    if (
      this.currentTabIndex === PulseCampaignEditTabsEnum.FOCUS &&
      !this.pulseCampaign.questions.length
    ) {
      if (this.isTemplate && this.isPreview) return this.buildTemplateWarnings()
    }

    if (this.isTemplate && this.currentTabIndex === PulseCampaignEditTabsEnum.BUILD) {
      this.steppersVM.initiateCompleted = true
      this.doInitiate = true
      this.saveValidation()
      return
    }

    if (this.currentTabIndex === PulseCampaignEditTabsEnum.SCHEDULE) {
      this.steppersVM.initiateCompleted = true
      this.doInitiate = true
      this.saveValidation()
    }

    if (this.currentTabIndex < PulseCampaignEditTabsEnum.SCHEDULE) {
      this.setCurrentTab(this.currentTabIndex + 1)
    }
  }

  @action
  public goToPreviousTab() {
    if (this.currentTabIndex < PulseCampaignEditTabsEnum.INITIATE) {
      this.setCurrentTab(this.currentTabIndex - 1)
    }
  }

  @action
  public setQuestions(questions: PulseQuestion[]) {
    this.clearAllQuestions()
    this.pulseCampaign.questions = questions
  }

  @action
  public updateQuestion(question: PulseQuestion) {
    const ndx = this.pulseCampaign.questions.findIndex((q) => q.objectId === question.objectId)
    if (ndx > -1) this.pulseCampaign.questions[ndx] = question.clone()
  }

  @action
  public addQuestion(question: PulseQuestion) {
    const ndx = this.pulseCampaign.questions.findIndex((q) => q.objectId === question.objectId)
    const copiedQuestion = question.clone()
    if (copiedQuestion.originalPulseQuestionId === '')
      copiedQuestion.setOriginalPulseQuestionId(copiedQuestion.objectId)
    if (ndx === -1) {
      this.pulseCampaign.questions.push(copiedQuestion)
    } else {
      this.pulseCampaign.questions[ndx] = copiedQuestion
    }
  }

  @action
  public addAllQuestions(questions: PulseQuestion[]) {
    questions.forEach((q) => this.pulseCampaign.questions.push(q))
  }

  @action
  public deleteQuestionById(objectId: string) {
    const ndx = this.getQuestionIndex(objectId)
    if (ndx !== -1) this.pulseCampaign.questions.splice(ndx, 1)
  }

  @action
  public clearAllQuestions() {
    this.pulseCampaign.questions = []
  }

  @action
  public getQuestionIndex(objectId): number {
    return this.pulseCampaign.questions.findIndex((e) => e.objectId === objectId)
  }

  @action
  public setQuestionsForCurrentSurvey(questions: PulseQuestion[]) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.setQuestions(questions)
  }

  @action
  public copyQuestionToCurrentSurvey(question: PulseQuestion) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    const copiedQuestion: PulseQuestion = question.clone()
    copiedQuestion.setOriginalPulseQuestionId(copiedQuestion.originalPulseQuestionId)
    copiedQuestion.setObjectId(HashId.generate())
    copiedQuestion.setId(HashId.generate())
    copiedQuestion.setOrganizationId(this.rootStore.appStore.currentOrgId)
    copiedQuestion.setTitle(copiedQuestion.title + ` (${this.lz.copy})`)
    this.currentSurvey.addQuestion(copiedQuestion)
  }

  @action
  public updateQuestionForCurrentSurvey(question: PulseQuestion) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.updateQuestion(question)
  }

  @action
  public addQuestionToCurrentSurvey(question: PulseQuestion) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    if (question.type === 'rating') question.ensureRatingValues()
    if (question.originalPulseQuestionId === '')
      question.setOriginalPulseQuestionId(question.objectId)
    this.currentSurvey.addQuestion(question.clone())
  }

  @action
  public addAllQuestionsToCurrentSurvey(questions: PulseQuestion[]) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.addAllQuestions(questions)
  }

  @action
  public deleteQuestionByIdFromCurrentSurvey(id: string) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.deleteQuestionById(id)
  }

  @action
  public deleteAllQuestionsByIdFromCurrentSurvey(objectId: string) {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.deleteAllQuestionsById(objectId)
  }

  @action
  public clearAllQuestionsFromCurrentSurvey() {
    if (!this.currentSurvey) return
    if (this.isCurrentSurveySent) return
    this.currentSurvey.clearAllQuestions()
  }

  @action
  public getQuestionIndexFromCurrentSurvey(objectId): number {
    if (!this.currentSurvey) return -1
    return this.currentSurvey.getQuestionIndex(objectId)
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return Boolean(this.rootStore.appStore.canManagePulseCampaigns)
  }

  @computed
  public get publishButtonText(): string {
    return this.isPublished ? this.lz.update : this.lz.initiate
  }

  @action
  public toggleConfirmSave() {
    this.confirmSaveDialogOpen = !this.confirmSaveDialogOpen
    if (!this.confirmSaveDialogOpen) this.doInitiate = false
  }

  @action
  public saveValidation() {
    this.markSaveDraftTried()
    if (this.doInitiate) {
      this.markPublishTried()
      if (!this.isAllValid) return
    } else {
      if (this.isPublished) {
        if (!this.areSurveysValid) return
      }
      if (!this.nameValid) return
      if (!this.scheduleValid) return
      if (!this.isPublishedByUserIdValid) return
    }
    if (this.isPublished || this.isTemplate || !this.doInitiate) this.save()
    else this.toggleConfirmSave()
  }

  @action
  public markPublishTried() {
    this.publishTried = true
  }

  @action
  public markSaveDraftTried() {
    this.saveDraftTried = true
  }

  @computed
  public get isMaxSurveys(): boolean {
    if (this.surveys.length >= this.maxPulseSurveys) return true
    return false
  }

  @computed
  public get isCurrentSurveySent(): boolean {
    if (this.currentSurvey.status === 'sent') return true
    if (this.currentSurvey.status === 'closed') return true
    return false
  }

  @computed
  public get isAllValid() {
    if (!this.saveDraftTried) return true
    if (!this.nameValid) return false
    if ((!this.isTemplate || this.isPreview) && !this.scheduleValid) return false
    if ((!this.isTemplate || this.isPreview) && !this.isParticipantsValid) return false
    if (!this.areSurveysValid) return false
    return true
  }

  @computed
  public get isPublishedByUserIdValid() {
    if (!this.saveDraftTried) return true
    if (!this.publishedByUserId || this.publishedByUserId.trim() === '') {
      return false
    }
    return true
  }

  @computed
  public get surveysSent() {
    const sent = []
    this.surveys.forEach((e, idx) => {
      if (e.status === 'sent') {
        sent.push(e.name)
      }
    })
    return sent.length > 0
  }

  @computed
  public get currentTemplate(): EmailTemplate {
    let template =
      this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        this.rootStore.appStore.currentOrgId,
        'campaign-survey-published'
      )
    if (!template)
      template = this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        this.rootStore.appStore.currentOrgId,
        'DEFAULT'
      )
    if (!template)
      template = this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        'DEFAULT',
        'DEFAULT'
      )
    if (template) return EmailTemplate.create(template)
    return undefined
  }

  @computed
  public get language(): string {
    const pref = this.rootStore.userStore.user.languagePreference
    if (!pref) return this.rootStore.localizationStore.language
    if (pref === 'English') return 'en'
    if (pref === 'German') return 'de'
    return ''
  }

  @computed
  public get notificationTemplate(): EmailTemplate {
    const selectedTemplate = this.selectOptions.find(
      (option) => option.value === this.selectedOptionValue
    )
    if (!selectedTemplate) return undefined
    let publishedTemplates = this.templates
    const t = publishedTemplates.find(
      (e) =>
        e.language === selectedTemplate.language &&
        e.notificationTypeId === this.currentlyEditingNotificationTypeId &&
        e.channel === this.currentlyEditingChannel
    )
    return t
  }

  @computed
  public get templates() {
    let publishedTemplates = []

    const orgTemplates = this.rootStore.notificationsStore.currentOrgNotificationTemplates.filter(
      (t) =>
        t.isDeleted === false &&
        t.channel === this.currentlyEditingChannel &&
        t.notificationTypeId === this.currentlyEditingNotificationTypeId
    )
    publishedTemplates = orgTemplates.map((t) => EmailTemplate.create(t))

    return publishedTemplates
  }

  @computed
  public get reminderTemplates(): EmailTemplate[] {
    let reminderTemplates = []

    const orgTemplates = this.rootStore.notificationsStore.currentOrgNotificationTemplates.filter(
      (t) =>
        t.isDeleted === false &&
        t.channel === this.currentlyEditingChannel &&
        t.notificationTypeId === 'campaign-survey-reminder'
    )
    reminderTemplates = orgTemplates.map((t) => EmailTemplate.create(t))

    return reminderTemplates
  }

  @action
  public resetTemplates() {
    if (this.cleanPublishedTemplates.length > 0) {
      this.currentSurvey.pulseCampaignSurvey.publishedTemplates = this.cleanPublishedTemplates
      this.cleanPublishedTemplates = this.currentSurvey.pulseCampaignSurvey.publishedTemplates.map(
        (t) => EmailTemplate.create(t)
      )
    }

    if (this.cleanReminderTemplates.length > 0) {
      this.currentSurvey.pulseCampaignSurvey.reminderTemplates = this.cleanReminderTemplates
      this.cleanReminderTemplates = this.currentSurvey.pulseCampaignSurvey.reminderTemplates.map(
        (t) => EmailTemplate.create(t)
      )
    }
  }

  @computed
  public get currentlyEditingNotificationTypeId(): string {
    return 'campaign-survey-published'
  }

  @computed
  public get currentlyEditingChannel(): string {
    return 'EMAIL'
  }

  @action
  private templateLanguageText(lang) {
    if (lang === 'en') return this.lz.english
    if (lang === 'de') return this.lz.german
    return 'English'
  }

  @computed
  public get selectOptions() {
    if (!this.templates) this.resetTemplates()
    return this.templates
      .filter(
        (template) =>
          template.notificationTypeId === this.currentlyEditingNotificationTypeId &&
          template.channel === this.currentlyEditingChannel
      )
      .map((template) => {
        const label = this.templateLanguageText(template.language)
        const value = `${template.notificationTypeId}-${template.channel}-${template.language}`
        return {
          label,
          value,
          notificationTypeId: template.notificationTypeId,
          channel: template.channel,
          language: template.language,
        }
      })
  }

  @action
  public setSelectedOptionValue(value) {
    this.selectedOptionValue = value
  }

  @action
  public resetSelectedOptionValue() {
    this.selectedOptionValue = 'campaign-survey-published-EMAIL-en'
  }

  @computed
  public get sgTemplateId(): string {
    const template =
      this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        this.rootStore.appStore.currentOrgId,
        'campaign-survey-published'
      )
    if (template) return template.sgTemplateId
  }

  @computed
  public get sgTemplateVersionId(): string {
    const template =
      this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        this.rootStore.appStore.currentOrgId,
        'campaign-survey-published'
      )
    if (template && template.sgTemplateVersionId) return template.sgTemplateVersionId
  }

  @action
  public setStartDate(val: any) {
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startTime.toISOString(),
        val.toISOString(),
        this.pulseCampaign.schedule.deliveryTimeZone
      )
      if (timeZonedDate < new Date().toISOString()) return
      this.campaignStartDate = val
      this.pulseCampaign.setStartDate(new Date(timeZonedDate))
      this.resetDates()
      this.isCampaignStartDateValid = true
    } catch (e) {
      this.isCampaignStartDateValid = false
    }
  }

  @action
  public to15Min(min) {
    const newMin = Math.round(min / 15) * 15
    return newMin
  }

  @action
  public setStartTime(hour: number, minute: number) {
    const newMin = this.to15Min(minute)
    const newTime = moment(this.startTime).hour(hour).minute(newMin).second(0)
    this.startTime = newTime.toDate()
    const timeZonedStartDate = this.setTimeOnDate(this.campaignStartDate)
    this.pulseCampaign.setStartDate(new Date(timeZonedStartDate))

    this.surveys.forEach((e, idx) => {
      if (e.isSent) return
      const currentDate = DateUtils.getBrowserDate(
        e.pulseCampaignSurvey.deliveryTime,
        e.pulseCampaignSurvey.deliveryTimeZone
      )
      const timeZonedSendDate = this.setTimeOnDate(currentDate)
      this.pulseCampaign.surveys[idx].setDeliveryTime(timeZonedSendDate)
    })
    if (this.autoCloseEnabled) this.setCloseDates()
  }

  @action
  public setTimeOnDate(date) {
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.startTime.toISOString(),
      date.toISOString(),
      this.pulseCampaign.schedule.deliveryTimeZone
    )
    return timeZonedDate
  }

  @computed
  public get weekdays() {
    const days = Object.keys(this.pulseCampaign.weekdays)
    return days.map((e) => new WeekdayVM(e, this))
  }

  @action
  public toggleWeekday(day) {
    this.pulseCampaign.toggleWeekday(day)
    this.resetDates(false)
  }

  @computed
  public get selectedWeekdays(): Array<number> {
    if (!this.pulseCampaign.weekdays) return []
    const indexes = []
    this.weekdays.forEach((e, index) => {
      if (e.isChecked) indexes.push(index)
    })
    return indexes
  }

  @action public convertDateToString(date) {
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.startTime.toISOString(),
      date.toISOString(),
      this.pulseCampaign.schedule.deliveryTimeZone
    )
    return timeZonedDate
  }

  private checkIfDateAlreadyExists(date) {
    const dateString = this.convertDateToString(date)
    const foundDate = this.surveyDates.find((e) => e.sendDate === dateString)
    if (foundDate) return true
    return false
  }

  private findNextAvailableDate(date, index) {
    const adDate = date.add(1, 'days').startOf('day').clone()
    if (this.selectedWeekdays.includes(adDate.day()) && !this.checkIfDateAlreadyExists(adDate)) {
      this.setDate(adDate, index)
    } else {
      this.findNextAvailableDate(adDate, index)
    }
  }

  @action public calculateSendDateV2(index) {
    let newDate = moment(this.surveyDates[index - 1].sendDate)
      .startOf('day')
      .clone()

    this.findNextAvailableDate(newDate, index)
  }

  @computed
  public get editIsDisabled(): boolean {
    return this.isReadOnly
  }

  @action
  public movePulseItem() {
    const dates = this.surveyDates
    //grab manually set settings
    const oldIndexSetMannually = this.surveys[this.oldIndex].isManuallySet
    const newIndexSetMannually = this.surveys[this.newIndex].isManuallySet
    //move pulse
    this.pulseCampaign.moveCampaignPulse(this.newIndex, this.oldIndex)
    //set manually set settings
    this.surveys[this.newIndex].setIsManuallySet(newIndexSetMannually)
    this.surveys[this.oldIndex].setIsManuallySet(oldIndexSetMannually)
    this.setCampaignPulseDeliveryDates(dates)
    this.setCloseDates()
  }

  @action
  public setCampaignPulseDeliveryDates(dates) {
    this.pulseCampaign.surveys.forEach((e, idx) => {
      this.setNewDate(dates[idx].sendDate, idx)
    })
  }

  @action
  public setNewDate(value, idx) {
    this.surveyDates[idx].setDate(value)
    this.pulseCampaign.surveys[idx].setDeliveryTime(value)
  }

  @action
  public setNewIndex(value) {
    this.newIndex = value
  }

  @action
  public setOldIndex(value) {
    this.oldIndex = value
  }

  @action
  public roundTo15Min() {
    const now = moment()
    const remainder = 15 - (now.minute() % 15)
    const currentDateRoundedToNext15Mins = now.add(remainder, 'minutes')
    return currentDateRoundedToNext15Mins.toDate()
  }

  @action
  public setCloseDates() {
    if (!this.autoCloseEnabled) return
    this.surveyDates.forEach((e, idx) => {
      const date = moment(e.sendDate).add(this.autoClose.value, this.autoClose.period)
      this.setCloseDate(date, idx)
    })
  }

  @action public resetDates(totalReset = true) {
    this.surveyDates.forEach((e, index) => {
      if (this.surveys[index].status === 'sent') return
      if (this.surveys[index].isManuallySet && !totalReset) return
      this.setDate(this.campaignStartDate, index)
    })
    if (this.selectedWeekdays.length === 0) return
    this.surveyDates.forEach((e, idx) => {
      if (this.surveys[idx].status === 'sent') return
      if (this.surveys[idx].isManuallySet && !totalReset) return
      if (idx === 0) {
        this.setDate(this.campaignStartDate, 0)
        return
      } else {
        this.calculateSendDateV2(idx)
      }
    })
    console.log('SurveyDates2: ' + this.surveyDates.join(', '))
    this.surveys.forEach((e, idx) => {
      if (this.surveys[idx].isManuallySet && !totalReset) return
      e.setIsManuallySet(false)
      if (e.status === 'sent') return
      if (!this.pulseCampaign.schedule) return
      e.setDeliveryTimeZone(this.pulseCampaign.schedule.deliveryTimeZone)
    })
    if (this.autoCloseEnabled) this.setCloseDates()
  }

  @action
  public setCloseDate(e, idx) {
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startTime.toISOString(),
        e.toISOString(),
        this.pulseCampaign.schedule.deliveryTimeZone
      )
      this.surveyDates[idx].setCloseDate(timeZonedDate)
    } catch (e) {}
  }

  @action
  public setDate(e, idx) {
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startTime.toISOString(),
        e.toISOString(),
        this.pulseCampaign.schedule.deliveryTimeZone
      )
      this.surveyDates[idx].setDate(timeZonedDate)
      this.surveys[idx].setDate(timeZonedDate)
    } catch (e) {}
  }

  @action
  public setDateManually(e, idx) {
    if (idx === 0) return
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startTime.toISOString(),
        e.toISOString(),
        this.pulseCampaign.schedule.deliveryTimeZone
      )
      if (timeZonedDate < this.campaignStartDate.toISOString()) return
      this.surveyDates[idx].setDateManually(timeZonedDate)
      this.surveys[idx].setDate(timeZonedDate)
      this.surveys[idx].setIsManuallySet(true)
      if (this.autoCloseEnabled) this.setCloseDates()
    } catch (e) {}
  }

  @computed
  public get surveyDates() {
    return this.surveys.map(
      (s) => new DateEditVM(this.rootStore, this, s.deliveryDate, s.deliveryTimeZone)
    )
  }

  @computed
  public get timeZones(): Array<TimeZoneType> {
    const timeZones = moment.tz.names()
    const offsetTmz = []
    for (const i in timeZones) {
      const tz = moment.tz(timeZones[i]).format('Z').replace(':00', '').replace(':30', '.5')
      let x = tz === '0' ? 0 : parseInt(tz).toFixed(2)

      const timeZone: TimeZoneType = {
        label: `(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`,
        value: `${timeZones[i]}`,
        time: `${x}`,
      }
      offsetTmz.push(timeZone)
    }

    return _.sortBy(offsetTmz, [
      function (el) {
        return -el.time
      },
    ])
  }

  @computed
  public get deliveryTimeZone(): TimeZoneType {
    return this.timeZones.find((e) => e.value === this.pulseCampaign.schedule.deliveryTimeZone)
  }

  @action
  public setDeliveryTimeZone(timeZone: any) {
    if (!timeZone) return
    this.pulseCampaign.setTimeZone(timeZone.value)
    const timeZonedStartDate = this.setTimeOnDate(this.campaignStartDate)
    this.campaignStartDate = new Date(timeZonedStartDate)
    this.pulseCampaign.setStartDate(new Date(timeZonedStartDate))
    if (this.pulseCampaign.surveys.length)
      this.pulseCampaign.surveys.forEach((e) => {
        if (e.status === 'sent') return
        e.setDeliveryTimeZone(timeZone.value)
        const timeZonedSendDate = this.setTimeOnDate(moment(e.deliveryTime))
        e.setDeliveryTime(timeZonedSendDate)
      })
    if (this.autoCloseEnabled) this.setCloseDates()
  }

  @computed
  public get tabNavigationBackButtonText() {
    if (this.currentTabIndex === 1 && this.isPreview) return this.lz.choose_template
    if (this.currentTabIndex === 1 && !this.isPreview) return this.lz.choose_details
    if (this.currentTabIndex === 2 && this.isPreview) return this.lz.choose_details
    if (this.currentTabIndex === 2 && !this.isPreview) return this.lz.choose_focus
    if (this.currentTabIndex === 3) return this.lz.build
    if ((!this.isTemplate || this.isPreview) && this.currentTabIndex === 4)
      return this.lz.set_audience
    if (this.isTemplate && !this.isPreview && this.currentTabIndex === 4) return this.lz.build
    else return ''
  }

  @computed
  public get tabNavigationNextButtonText() {
    if (this.currentTabIndex === 0 && this.isPreview) return this.lz.details
    if (this.currentTabIndex === 0 && !this.isPreview) return this.lz.focus
    if (this.currentTabIndex === 1) return this.lz.build
    if ((!this.isTemplate || this.isPreview) && this.currentTabIndex === 2)
      return this.lz.set_audience
    if (this.currentTabIndex === 3) return this.lz.schedule
    else return ''
  }

  @computed
  public get maxPulseSurveys() {
    return this.MAX_PULSE_SURVEYS
  }

  @action
  public deepEqual(object1, object2) {
    if (this.isDirty) return
    if (this.isSaving) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      this.isDirty = true
    }
    for (const key of keys1) {
      if (this.isDirty) return
      const val1 = object1[key]
      const val2 = object2[key]
      // added to avoid false flag on empty mobx arrays (categoryIds)
      if (JSON.stringify(val1) === '[]' && JSON.stringify(val2) === '[]') return
      if (val1 !== val2) {
        this.isDirty = true
      }
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects && !this.isDirty) this.deepEqual(val1, val2)
    }
  }

  @action
  public isObject(object) {
    return object != null && typeof object === 'object'
  }

  @computed
  public get infoString() {
    let participants = this.participantsSelectVM.participants.length
    if (participants === 0) participants = this.pulseCampaign.participants.length
    const surveys = JSON.stringify(this.pulseCampaign.surveys)
    const string = this.participantsSelectVM.anonymousParticipants.length + participants + surveys
    return string
  }

  @action
  public autoSaveValidation() {
    if (this.isPublished) return
    if (this.autoSaveInt) clearInterval(this.autoSaveInt)
    this.autoSaveInt = setInterval(this.autoSave.bind(this), 60000)
  }

  @action
  public async autoSave() {
    // if (!this.isDirty) return
    // if (this.isSaving) return
    // if (this.pulseCampaign.status === 'draft' && !this.nameValid) {
    //   this.openSnackbar(`${this.lz.error} ${this.lz.you_must_name_to_autosave}`)
    //   return
    // }
    // const url = window.location.href
    // if (
    //   !url.includes('/pulse/v1/edit') &&
    //   !url.includes('/pulse/v1/fromtemplate/preview') &&
    //   !url.includes('/pulse/v1/fromtemplate/edit')
    // ) {
    //   clearInterval(this.autoSaveInt)
    //   return
    // }
    // const fromTemplate = this.isPreview
    // if (fromTemplate && !this.isTemplateUpdate) {
    //   this.pulseCampaign.setObjectId(undefined)
    //   this.pulseCampaign.isTemplate = false
    // }
    // if (this.isTemplate && !this.pulseCampaign.name.includes('[Auto-saved Draft]')) {
    //   this.pulseCampaign.name = `${this.name} [Auto-saved Draft]`
    // }
    // this.isSaving = true
    // const postData = this.toDTO()
    // this.isDirty = false
    // const svc = new PulseCampaignsService()
    // const req = {
    //   organizationId: this.rootStore.appStore.currentOrgId,
    //   pulseCampaign: postData,
    // } as IPulseCampaignSaveRequest
    // let result
    // try {
    //   result = await svc.savePulseCampaign(req)
    //   if (result && result.success) {
    //     if (result.pulseCampaign.status === 'draft') {
    //       if (result.pulseCampaign.isTemplate) {
    //         this.openSnackbar(`${this.lz.success}  ${this.lz.template_has_been_autosaved}`)
    //       } else {
    //         this.openSnackbar(`${this.lz.success}  ${this.lz.campaign_has_been_autosaved}`)
    //       }
    //       this.pulseCampaign.setObjectId(result.pulseCampaign.objectId)
    //     }
    //     this.isSaving = false
    //   } else {
    //     this.pulseCampaign.setStatus('draft')
    //     const msg = result.errorMessage
    //     console.debug(msg)
    //     this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_autosave}`)
    //     this.isSaving = false
    //     this.isDirty = true
    //     return
    //   }
    // } catch (e) {
    //   this.isSaving = false
    //   console.error(e)
    //   this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
    //   Sentry.captureException({
    //     message: 'Unable to autosave pulse campaign',
    //     exception: e,
    //     userId: this.rootStore.appStore.currentUserId,
    //     orgId: this.rootStore.appStore.currentOrgId,
    //   })
    //   return
    // }
  }

  @action
  public async save() {
    this.confirmSaveDialogOpen = false
    const fromTemplate = this.isPreview
    if (fromTemplate && !this.isTemplateUpdate) {
      this.pulseCampaign.setObjectId(undefined)
      this.pulseCampaign.isTemplate = false
    }
    if (this.doInitiate) {
      if (!this.isTemplate) this.pulseCampaign.setStatus('initiated')
    }
    if (this.pulseCampaign.status === 'draft' && !this.nameValid) {
      this.openSnackbar(`${this.lz.error} ${this.lz.you_must_name}`)
      return
    }
    if (this.pulseCampaign.name.includes('[Auto-saved Draft]')) {
      let name = this.pulseCampaign.name.replace('[Auto-saved Draft]', '')
      this.setName(name)
    }
    this.isSaving = true
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
    const postData = this.toDTO()
    this.isDirty = false
    const svc = new PulseCampaignsService()
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      pulseCampaign: postData,
    } as IPulseCampaignSaveRequest
    let result
    try {
      result = await svc.savePulseCampaign(req)
      if (result && result.success) {
        if (result.pulseCampaign.status === 'draft') {
          if (result.pulseCampaign.isTemplate) {
            this.openSnackbar(`${this.lz.success}  ${this.lz.template_has_been_saved}`)
            if (!this.isTemplateUpdate) {
              setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
            } else {
              this.pulseCampaign.pulseQuestionIds = result.pulseCampaign.pulseQuestionIds
              this.loadData(this.pulseCampaign, true)
              this.resetStepper()
            }
            this.isTemplateUpdate = false
          } else {
            this.openSnackbar(`${this.lz.success}  ${this.lz.campaign_has_been_saved}`)
            setTimeout(
              () =>
                this.rootStore.appStore.router.push(
                  `/pulse/v1/edit/${result.pulseCampaign.objectId}`
                ),
              1000
            )
            if (fromTemplate && !this.isTemplateUpdate)
              setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
          }
        } else {
          if (
            !req.pulseCampaign.objectId &&
            result.pulseCampaign.objectId &&
            result.pulseCampaign.status === 'initiated'
          ) {
            this.openSnackbar(`${this.lz.success}  ${this.lz.has_been_initiated}`)
            setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
          } else {
            this.openSnackbar(`${this.lz.success}  Your Campaign has been updated`)
            setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
          }
        }
        this.isSaving = false
      } else {
        this.pulseCampaign.setStatus('draft')
        const msg = result.errorMessage
        console.debug(msg)
        this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
        this.isSaving = false
        this.isDirty = true
        return
      }
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
      Sentry.captureException({
        message: 'Unable to save pulse campaign',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public async loadSelectedQuestions(questions: string[]): Promise<PulseQuestion[]> {
    if (questions.length < 1) {
      this.isLoading = false
      return
    }

    const request = {
      pulseQuestionIds: questions.slice(),
      limit: questions.length,
    } as IPulseQuestionsFindRequest

    const dataStore = new PulseQuestionsDataStore(this.rootStore, request)
    dataStore.loadListRecords()

    this.reactions.push(
      when(
        () => dataStore.isLoaded,
        () => {
          if (dataStore.rows.length > 0) this.pulseCampaign.questions = dataStore.rows.slice()
          if (this.isPreview && this.templatesVM)
            this.templatesVM.templateLoaded(this.pulseCampaign.clone())
          this.isLoading = false
        }
      )
    )
    return []
  }

  public toDTO(): IPulseCampaignDTO {
    const dto = this.pulseCampaign.serialize()
    dto.participants = this.participantsSelectVM.participantsToDTO() as IParticipantDTO[]
    dto.pulseQuestionIds = this.pulseCampaign.questions.map((question) => question.objectId)
    return dto
  }

  @action
  public async delete() {
    if (this.isTemplate) return await this.deletePulseCampaignTemplate()
    this.showDeleteCampaignDialog = false
    let result
    const svc = new PulseCampaignsService()
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      pulseCampaignId: this.pulseCampaign.objectId,
    } as IPulseCampaignDeleteRequest
    try {
      result = await svc.deletePulseCampaign(req)
      if (result && result.success) {
        this.openSnackbar(`${this.lz.success}  This campaign has been deleted`)
        setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
      }
    } catch (e) {
      console.error(e)
      this.openSnackbar(`${this.lz.error} Unable to delete campaign`)
      Sentry.captureException({
        message: 'Unable to delete pulse campaign',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public async close() {
    this.showCloseCampaignDialog = false
    let result
    const svc = new PulseCampaignsService()
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      pulseCampaignId: this.pulseCampaign.objectId,
    } as IPulseCampaignDeleteRequest
    try {
      result = await svc.closePulseCampaign(req)
      if (result && result.success) {
        this.openSnackbar(`${this.lz.success}  This campaign has been closed`)
        setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
      }
    } catch (e) {
      console.error(e)
      this.openSnackbar(`${this.lz.error} Unable to close campaign`)
      Sentry.captureException({
        message: 'Unable to close pulse campaign',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public copyPulseCampaign() {
    if (this.isTemplate)
      return this.rootStore.appStore.router.push(`pulse/v1/fromtemplate/fromCopy/${this.objectId}`)
    this.rootStore.appStore.router.push(`/pulse/v1/fromCopy/${this.objectId}`)
  }

  @action
  public async deletePulseCampaignTemplate() {
    this.showDeleteCampaignDialog = false
    let result
    const svc = new PulseCampaignsService()
    const req = {
      organizationId: this.pulseCampaign.organizationId,
      pulseCampaignId: this.pulseCampaign.objectId,
    } as IPulseCampaignDeleteRequest
    try {
      result = await svc.deletePulseCampaignTemplate(req)
      if (result && result.success) {
        this.openSnackbar(`${this.lz.success}  This campaign template has been deleted`)
        setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
      }
    } catch (e) {
      console.error(e)
      this.openSnackbar(`${this.lz.error} Unable to delete campaign template`)
      Sentry.captureException({
        message: 'Unable to delete pulse campaign template',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public setBody(value: string) {
    this.body = value
    this.isDirty = true
  }

  @computed
  public get TOOLBAR_UPDATE(): string {
    return TOOLBAR.replaceAll('preview', '')
  }

  @computed
  public get tinyMCEInit() {
    const init = {
      branding: false,
      height: 535,
      resize: false,
      menubar: false,
      plugins: PLUGINS,
      toolbar: this.TOOLBAR_UPDATE,
      powerpaste_block_drop: true,
      placeholder: this.rootStore.localizationStore.lzStrings.pulseCampaign.body_text,
      browser_spellcheck: true,
      contextmenu: false,
    } as any
    return init
  }

  @action
  public replaceMarkupForPreview(emailTemplate: EmailTemplate) {
    if (!emailTemplate) return ''

    return emailTemplate.body
      .replaceAll('<!DOCTYPE html>', '')
      .replaceAll('<html', '<div')
      .replaceAll('</html', '</div')
      .replaceAll('<body', '<div')
      .replaceAll('</body', '</div')
      .replaceAll('<head', '<div')
      .replaceAll('</head', '</div')
      .replaceAll(/\{\w*Link\}/g, 'javascript:void(0)')
  }

  @action
  public replaceMarkupAndKeywordsForPreview(emailTemplate: EmailTemplate, surveyIntroVal: string) {
    if (!emailTemplate) return ''

    return this.replaceMarkupForPreview(emailTemplate)
      .replaceAll('{surveyIntroduction}', surveyIntroVal)
      .replaceAll('{pulseIntroduction}', surveyIntroVal)
  }

  @action
  public replaceKeywordsForPreview(body: string, emailTemplate: EmailTemplate) {
    if (!emailTemplate) return ''

    let replaced = emailTemplate.html ? emailTemplate.html : ''
    try {
      const testData = emailTemplate.test_data ? JSON.parse(emailTemplate.test_data) : undefined
      if (!testData) return replaced
      Object.keys(testData).forEach((key) => {
        if (key === 'body') replaced = replaced.replaceAll('{{{body}}}', body)
        else replaced = replaced.replaceAll(`{{{${key}}}}`, testData[key])
      })
    } catch (e) {
      console.debug(e)
      this.openSnackbar(`${this.lz.error}: Fix the JSON test data to see a valid preview.`)
    }

    replaced = replaced.replaceAll(`target="_blank"`, '')
    return replaced
  }

  @action
  public toggleSendReminders() {
    this.pulseCampaign.toggleSendReminders()
  }

  @computed
  public get sendReminders(): boolean {
    return this.pulseCampaign.sendReminders
  }

  @computed
  public get autoCloseEnabled(): boolean {
    return this.pulseCampaign.autoClose.enabled
  }

  @action
  public toggleAutoClose() {
    this.pulseCampaign.toggleAutoClose()
    if (this.autoCloseEnabled) this.setCloseDates()
  }

  @computed
  public get autoClose(): AutoCloseVM {
    return new AutoCloseVM(this.pulseCampaign.autoClose, this)
  }

  @computed
  public get alarms() {
    return this.pulseCampaign.alarms
  }

  @computed
  public get periodReminders(): Array<ReminderVM> {
    return this.alarms.selectedAlarms
      .filter((e) => e.mode === 'period')
      .map((e) => new ReminderVM(e))
  }

  @action
  public addReminder(obj) {
    this.pulseCampaign.addAlarm(obj)
  }

  @action
  deleteReminder(mode) {
    this.pulseCampaign.deleteAlarm(mode)
  }

  @action
  setSurveyCompletedContent(val) {
    this.currentSurvey.setSurveyCompletedContent(val)
  }

  @computed
  public get availableOwnerOptions() {
    return this.availableOwners
      .sort((a, b) => {
        var nameA = a.fk_User.name ? a.fk_User.name.toLowerCase() : ''
        var nameB = b.fk_User.name ? b.fk_User.name.toLowerCase() : ''
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
      })
      .map((e) => {
        return { userId: e.userId, name: e.fk_User.name }
      })
  }

  @computed
  public get ownerUser(): any {
    let foundUser = this.availableOwnerOptions.find((e) => e.userId === this.tempOwnerUserId)
    if (!foundUser) return { name: '' }
    return foundUser
  }

  @action
  public setTempOwnerUser(ownerUserId: string) {
    this.tempOwnerUserId = ownerUserId
  }

  @action
  public changeCampaignOwner() {
    this.pulseCampaign.setOwnerUserId(this.tempOwnerUserId)
    this.ownerDialogOpen = false
  }

  @action
  public toggleShowOwnerDialog() {
    this.ownerDialogOpen = !this.ownerDialogOpen
  }

  @action
  public openChangeOwnerDialog() {
    this.tempOwnerUserId = this.pulseCampaign.ownerUserId
    this.ownerDialogOpen = true
  }

  @action
  public toggleAnonymizeResults() {
    this.pulseCampaign.toggleAnonymizeResults()
  }

  @computed
  public get hasSelectedSurveyTemplate(): boolean {
    if (this.currentSurvey.isSurveyTemplate) return true
    if (this.currentSurvey.selectedSurveyTemplate) return true
    return false
  }

  @computed
  public get templateHeaderText(): string {
    if (this.hasSelectedSurveyTemplate) return 'Selected Survey Template'
    return 'Choose Survey Template'
  }

  @computed
  public get pulseHasQuestions(): boolean {
    if (this.currentSurvey && this.currentSurvey.questions.length > 0) return true
    return false
  }
}
