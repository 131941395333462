import React, { useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { ActionableInsightsVM } from '../view-models/ActionableInsightsVM'
import { observer, useLocalStore } from 'mobx-react'
import './ActionableInsightsHomeScreen.scss'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  FaBolt,
  FaSun,
  FaCloudRain,
  FaInfoCircle,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaMinus,
} from 'react-icons/fa'
import ActionableInsightsFrameCard from './ActionableInsightsFrameCard'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'

interface Props {}

const ActionableInsightsHomeScreen: React.FC<Props> = () => {
  const localStore = useLocalStore(() => ({
    vm: new ActionableInsightsVM(),
  }))
  const vm = localStore.vm
  if (!vm) return

  useEffect(() => {
    return () => vm.dispose()
  }, [])

  if (!vm.shouldRender) {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderLoadingSpinner = () => {
    if (!vm.spinnerShown) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderInsightPeriod = () => {
    if (vm.emptyStateMessageShown) {
      return <p className='insights-subheader support-text-md'>Report coming soon</p>
    } else {
      return (
        <p className='insights-subheader support-text-md'>
          Insight Period: {vm.insightPeriodDates}
        </p>
      )
    }
  }

  const renderHeader = () => {
    return (
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className='header-container'
      >
        <Grid item xs={6}>
          <h1 className='insights-header h1-sm'>Actionable Insights: Climate Campaign</h1>
          {renderInsightPeriod()}
        </Grid>
        {renderDropdowns()}
      </Grid>
    )
  }

  const renderInsightPeriodDropdown = () => {
    const dropDownClass = 'insights-dropdown label'

    const renderOptions = () => {
      if (!vm.periods) return
      return vm.periods.map((option) => (
        <MenuItem key={option.OrgPeriodPk} onClick={() => vm.setSelectedPeriod(option.OrgPeriodPk)}>
          {`Pulses ${option.PeriodRange}`}
        </MenuItem>
      ))
    }

    return (
      <div className='left-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Insight Period</span>
        <Button
          className={dropDownClass}
          variant='outlined'
          aria-label='View Insight Periods'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openInsightPeriodMenu(e.currentTarget)}
        >
          {vm.selectedPeriodName}
        </Button>
        <Menu
          id='InsightPeriodMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.insightPeriodAnchorEl}
          keepMounted
          open={vm.insightPeriodMenuShown}
          onClose={() => vm.closeInsightPeriodMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderOptions()}
        </Menu>
      </div>
    )
  }

  const renderGroupDropdown = () => {
    const renderOptions = () => {
      if (!vm.groups) return
      return vm.groups.map((option) => (
        <MenuItem key={option.GrpPk} onClick={() => vm.setSelectedGroup(option.GrpPk)}>
          {option.GroupName}
        </MenuItem>
      ))
    }

    return (
      <div className='right-dropdown-wrapper'>
        <span className='insights-dropdown-label'>Group</span>
        <Button
          className='insights-dropdown label'
          variant='outlined'
          aria-label='View Groups'
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => vm.openGroupMenu(e.currentTarget)}
        >
          {vm.selectedGroupName}
        </Button>
        <Menu
          id='GroupMenu'
          className='insight-dropdown-menu'
          elevation={2}
          anchorEl={vm.groupAnchorEl}
          keepMounted
          open={vm.groupMenuShown}
          onClose={() => vm.closeGroupMenu()}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderOptions()}
        </Menu>
      </div>
    )
  }

  const renderDropdowns = () => {
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid item xs={3}>
          {renderInsightPeriodDropdown()}
        </Grid>
        <Grid item xs={3}>
          {renderGroupDropdown()}
        </Grid>
      </>
    )
  }

  const useStyles = makeStyles(() => ({
    tooltip: {
      fontFamily: 'Helvetica Neue',
      backgroundColor: 'white !important',
      color: '#212227',
      boxShadow: '0px 8px 16px 0px #1B212C1F',
      fontSize: '14px !importnat',
      fontWeight: 400,
      lineHeight: '20px',
      padding: '16px',
      borderRadius: '8px',
    },
    arrow: {
      color: 'white !important',
    },
  }))

  function SentimentTooltip(props) {
    const classes = useStyles()
    return <Tooltip classes={classes} arrow {...props} />
  }

  const renderSentimentTrend = () => {
    const scoreColor = vm.sentTrendDirection === '-' ? 'sentiment-score-disabled' : ''
    const renderIcon = () => {
      if (vm.sentTrendDirection === 'Positive')
        return <FaLongArrowAltUp className='sentiment-direction-icon green-arrow' />
      if (vm.sentTrendDirection === 'Negative')
        return <FaLongArrowAltDown className='sentiment-direction-icon red-arrow' />
      if (vm.sentTrendDirection === 'Neutral')
        return <FaMinus className='sentiment-neutral-icon grey-neutral' />
      return null
    }
    const renderAsterisk = () => {
      if (vm.sentTrendValue === '-')
        return <span className='sentiment-asterisk support-text-md'>*</span>
      return null
    }
    return (
      <div className='sentiment-direction-wrapper'>
        {renderIcon()}
        <span className={`sentiment-direction-percentage ${scoreColor} support-text-md`}>
          {vm.sentTrendValue + '%'}
        </span>
        {renderAsterisk()}
      </div>
    )
  }

  const renderTrend = (direction: string, value: string, color: string) => {
    const scoreColor = value === '-' ? 'disabled' : ''
    const renderIcon = () => {
      if (direction === 'Positive')
        return <FaLongArrowAltUp className='trend-direction-icon green-arrow' />
      if (direction === 'Negative')
        return <FaLongArrowAltDown className='trend-direction-icon red-arrow' />
      if (direction === 'Neutral') return <FaMinus className='trend-neutral-icon grey-neutral' />
      return null
    }
    const renderAsterisk = () => {
      if (value === '-') return <span className='score-asterisk support-text-md'>*</span>
      return null
    }
    return (
      <div className='trend-direction-wrapper'>
        {renderIcon()}
        <span className={`trend-direction-percentage ${scoreColor} support-text-md`}>
          {value + '%'}
        </span>
        {renderAsterisk()}
      </div>
    )
  }

  const renderProgressionDataMessage = () => {
    if (vm.progressionDataAvailable) return
    return (
      <p className='special-note support-text'>
        *Note: Progression data will be available in the next report.
      </p>
    )
  }

  const renderLeftContainer = () => {
    return (
      <Grid item xs={3} className='left-container'>
        <div className='group-container'>
          <h3 className='group-name h2-sm'>{vm.selectedGroupName}</h3>
          <p className='group-summary-text body-sm'>{vm.directive}</p>
        </div>
        <div className='sentiment-container'>
          <h5 className='sentiment-title h6'>Overall Sentiment</h5>
          <SentimentTooltip
            title='Overall sentiment combines scores from rating questions and open text feedback to give a snapshot of how people feel with "100" being Very Positive and "0" being Very Negative.'
            placement='right'
          >
            <IconButton className='sentiment-tool-tip' disableRipple>
              <FaInfoCircle className='sentiment-tool-tip-icon' />
            </IconButton>
          </SentimentTooltip>
          <div className='sentiment-score-wrapper'>
            <div className='sentiment-score-inner-wrapper'>
              <span className='sentiment-score callout-lg'>{vm.sentimentScore}</span>
              <span className='sentiment-score-out-of callout-sm'>/100</span>
            </div>
            {renderSentimentTrend()}
          </div>
        </div>
        <div className='response-container'>
          <h6 className='response-title h6'>Response</h6>
          <div className='response-score-wrapper'>
            <span className='response-percentage callout'> {vm.responseRate + '%'}</span>
            {renderTrend(vm.respTrendDirection, vm.respTrendValue, vm.respTrendColor)}
          </div>
        </div>
        <div className='motivation-container'>
          <h6 className='motivation-title h6'>Motivation</h6>
          <div className='motivation-score-wrapper'>
            <div className='motivation-score-inner-wrapper'>
              <span className='motivation-score callout'>{vm.motivationScore}</span>
              <span className='motivation-score-out-of callout-sm'>/100</span>
            </div>
            {renderTrend(vm.motivTrendDirection, vm.motivTrendValue, vm.motivTrendColor)}
          </div>
          <p className='motivation-perception body-italicized'>{vm.motivationLabel}</p>
        </div>
        <div className='workload-container'>
          <h6 className='workload-title h6'>Workload</h6>
          <div className='workload-score-wrapper'>
            <div className='workload-score-inner-wrapper'>
              <span className='workload-score callout'>{vm.workloadScore}</span>
              <span className='workload-score-out-of callout-sm'>/100</span>
            </div>
            {renderTrend(vm.workTrendDirection, vm.workTrendValue, vm.workTrendColor)}
          </div>
          <p className='workload-perception body-italicized'>{vm.workloadLabel}</p>
        </div>
        {renderProgressionDataMessage()}
      </Grid>
    )
  }

  const renderInsightsContent = () => {
    return (
      <Grid item xs={9}>
        <ActionableInsightsFrameCard frame='Individual' insights={vm.insights} />
        <ActionableInsightsFrameCard frame='Team' insights={vm.insights} />
        <ActionableInsightsFrameCard frame='Organization' insights={vm.insights} />
      </Grid>
    )
  }

  const renderContent = () => {
    if (vm.spinnerShown) return
    if (vm.emptyStateMessageShown) return
    return (
      <>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          className='insights-legend'
        >
          <Grid item>
            <span className='legend-title'>Sentiment score:</span>
          </Grid>
          <Grid item className='legend-item-wrapper'>
            <FaBolt className='legend-icon red-lightning' />
            <span className='legend-item label'>0-33</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '4px' }}>
            <FaCloudRain className='legend-icon yellow' />
            <span className='legend-item label'>34-67</span>
          </Grid>
          <Grid item className='legend-item-wrapper' style={{ gap: '2px' }}>
            <FaSun className='legend-icon green-sun' />
            <span className='legend-item label'>68-100</span>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justifyContent='space-between'
          className='content-container'
        >
          {renderLeftContainer()}
          {renderInsightsContent()}
        </Grid>
      </>
    )
  }

  const renderNoPeriodMessage = () => {
    if (!vm.emptyStateMessageShown) return
    return (
      <Grid item xs={12} className='no-periods-container'>
        <div className={'viz-empty-avatar'}>
          <img className={'no-periods-img'} alt='Empty' src={emptyResults} />
        </div>
        <h1 className='no-periods-header-text'>Report coming soon</h1>
        <h6 className='no-periods-message-text'>
          Hang tight! This report will be available once sufficient data is collected
          <br />
          Encourage participation to ensure robust and reliable insights
        </h6>
      </Grid>
    )
  }

  return (
    <div id='Insights'>
      {renderLoadingSpinner()}
      <div className='insights-container'>
        {renderHeader()}
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          {renderNoPeriodMessage()}
          {renderContent()}
        </Grid>
      </div>
    </div>
  )
}

export default observer(ActionableInsightsHomeScreen)
