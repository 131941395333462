import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import './ScheduleStep.scss'
import CheckIcon from '@material-ui/icons/Check'
import PulseTimePicker from '../../../shared/time-picker/PulseTimePicker'
import PulseSurveyCard from './PulseSurveyCard'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import DeleteIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { ReminderVM } from '../../../surveys/view-models/ReminderVM'
import { AutoCloseVM } from '../../view-models/AutoCloseVM'
import moment from 'moment'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }}>{value}</div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

const ScheduleStep: React.FC<Props> = ({ pulseCampaignsStore, localizationStore, labelsStore }) => {
  const { pulseCampaign: lz, surveys: slz } = localizationStore.lzStrings
  const { editVM } = pulseCampaignsStore.viewModels
  if (!editVM) return null

  const renderStartDate = () => {
    return (
      <>
        <Typography className='schedule-text-secondary'>{lz.start_date}</Typography>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          disabled={editVM.surveysSent}
          error={!editVM.isStartDateValid}
          variant='inline'
          inputVariant='standard'
          format='MM/dd/yyyy'
          invalidDateMessage={null}
          margin='none'
          InputAdornmentProps={{ position: 'start' }}
          InputProps={{
            disableUnderline: true,
          }}
          value={editVM.campaignStartDate}
          onChange={(e) => editVM.setStartDate(e)}
          className={editVM.isStartDateValid ? 'date-picker' : 'date-picker sch-error'}
        />
      </>
    )
  }

  // const renderEndDate = () => {
  //   return (
  //     <>
  //       <Typography className='schedule-text-secondary schedule-text-margin'>
  //         {lz.end_date}
  //       </Typography>
  //       <KeyboardDatePicker
  //         disableToolbar
  //         autoOk
  //         error={!editVM.isEndDateValid}
  //         variant='inline'
  //         inputVariant='standard'
  //         format='MM/dd/yyyy'
  //         invalidDateMessage={null}
  //         margin='none'
  //         InputAdornmentProps={{ position: 'start' }}
  //         InputProps={{
  //           disableUnderline: true,
  //         }}
  //         value={editVM.campaignEndDate}
  //         onChange={(e) => editVM.setEndDate(e)}
  //         className={editVM.isEndDateValid ? 'date-picker' : 'date-picker sch-error'}
  //       />
  //     </>
  //   )
  // }

  const renderSendTime = () => {
    return (
      <>
        <Typography className='schedule-text-secondary schedule-text-margin'>
          {lz.send_time}
        </Typography>
        <PulseTimePicker
          textFieldClassName='pulse-time-picker'
          error={false}
          value={editVM.startTime}
          key={String(editVM.vmCreatedAt)}
          onChange={(hr, min) => editVM.setStartTime(hr, min)}
        />
      </>
    )
  }

  const renderTimezone = () => {
    return (
      <>
        <Typography className='schedule-text-secondary schedule-text-margin'>
          {lz.time_zone}
        </Typography>
        <Autocomplete
          className='pulse-time-picker'
          options={editVM.timeZones}
          getOptionLabel={(e) => e.label}
          value={editVM.deliveryTimeZone}
          onChange={(e, val) => editVM.setDeliveryTimeZone(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              className='pulse-survey-timeZone-picker'
              placeholder='Select Time Zone'
              variant='standard'
            />
          )}
        />
      </>
    )
  }

  const renderIncludedDays = () => {
    return (
      <>
        <Typography className='schedule-text-secondary schedule-text-margin'>
          {lz.included_days}
        </Typography>
        <div className='weekly-container'>
          {editVM.weekdays.map((e, index) => renderWeekdayButton(e, index))}
        </div>
      </>
    )
  }

  const renderWeekdayButton = (e, index) => {
    if (e.isChecked) {
      return (
        <Button
          style={{ textTransform: 'capitalize' }}
          className='weekday-selected'
          key={'wd' + index}
          value={index}
          onClick={() => e.toggleChecked()}
        >
          {e.subString}
          <CheckIcon className='check-icon' />
        </Button>
      )
    } else {
      return (
        <Button
          className='weekday-unselected'
          style={{ textTransform: 'capitalize' }}
          key={'wd' + index}
          value={index}
          onClick={() => e.toggleChecked()}
        >
          {e.subString}
        </Button>
      )
    }
  }

  const renderSendSchedule = () => {
    return (
      <div>
        <Grid container className='schedule-header'>
          <Typography className='schedule-text'>{lz.send_schedule}</Typography>
          {renderResetButton()}
        </Grid>
        <Grid container>
          {renderDatesAndSurveys()}
          {renderNoSurveys()}
        </Grid>
      </div>
    )
  }

  const renderResetButton = () => {
    if (!editVM.surveys.length) return
    return (
      <Tooltip
        title={`${labelsStore.getLabelByLanguageAndFor(
          'pulses'
        )} set to next available day based on the options selected`}
        placement='bottom-start'
      >
        <Button
          className='reset-dates'
          style={{ textTransform: 'capitalize' }}
          onClick={() => editVM.resetDates()}
        >
          {lz.reset_to_auto_schedule}
        </Button>
      </Tooltip>
    )
  }

  const renderSurveySendDateContainer = () => {
    return (
      <Grid item xs={3} className='date-margin'>
        <Typography className='schedule-text-tertiary schedule-text-margin'>
          {'SEND DATE'}
        </Typography>
        <Grid container>{renderSendDateFields()}</Grid>
      </Grid>
    )
  }

  const renderSurveyCloseDateContainer = () => {
    if (!editVM.autoCloseEnabled) return null
    return (
      <Grid item xs={3} className='date-margin'>
        <Typography className='schedule-text-tertiary schedule-text-margin'>
          {lz.close_date}
        </Typography>
        <Grid container>{renderCloseDateFields()}</Grid>
      </Grid>
    )
  }

  const renderDatesAndSurveys = () => {
    if (!editVM.surveys.length) return
    return (
      <Grid container>
        <Grid item xs={1} className='survey-num'>
          <Typography className='schedule-text-tertiary schedule-text-margin'>{lz.num}</Typography>
          <Grid container>{renderSurveyNumbers()}</Grid>
        </Grid>
        {renderSurveySendDateContainer()}
        {renderSurveyCloseDateContainer()}
        <Grid item xs={editVM.autoCloseEnabled ? 5 : 7} className=''>
          <Typography className='schedule-text-tertiary schedule-text-margin'>
            {`${labelsStore.getLabelByLanguageAndFor('pulse').toUpperCase()} ${lz.name}`}
          </Typography>
          {renderPulseCards()}
        </Grid>
      </Grid>
    )
  }

  const renderNoSurveys = () => {
    if (editVM.surveys.length) return
    return (
      <>
        <Typography className='schedule-text-alarm'>
          {`(${lz.no} ${labelsStore.getLabelByLanguageAndFor('pulses')} ${lz.to_schedule})`}
        </Typography>
      </>
    )
  }

  const renderSendDateFields = () => {
    return editVM.surveys.map((e, idx) => renderSendDateField(e, idx))
  }

  const renderSendDateField = (e, idx) => {
    const edited = editVM.surveys[idx].isManuallySet
    let pickerClass = 'pulse-survey-date-picker'
    if (editVM.publishTried && !editVM.surveyDates[idx].sendDate)
      pickerClass = 'pulse-survey-date-picker sch-error'
    if (edited) pickerClass = 'date-picker-edited'
    const textClass = edited ? 'text-edited' : ''
    // const pickerClass =
    //   editVM.publishTried && !editVM.surveyDates[idx].sendDate
    //     ? 'pulse-survey-date-picker sch-error'
    //     : 'pulse-survey-date-picker'
    const timezone = editVM.surveyDates[idx].timezone
    const tooltip =
      moment(editVM.surveyDates[idx].sendDate).tz(timezone).format('MMMM Do YYYY, h:mm a ') +
      timezone
    return (
      <Grid item xs={12} key={'pulse' + idx}>
        <Tooltip title={tooltip} placement='top-end'>
          <div>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              disabled={!editVM.surveys[idx].isEditable}
              variant='inline'
              inputVariant='standard'
              format='MM/dd/yyyy'
              invalidDateMessage={null}
              margin='none'
              InputAdornmentProps={{ position: 'start' }}
              InputProps={{
                disableUnderline: true,
                className: textClass,
              }}
              value={editVM.surveyDates[idx].sendDate}
              onChange={(e) => editVM.setDateManually(e, idx)}
              className={pickerClass}
            />
          </div>
        </Tooltip>
      </Grid>
    )
  }

  const renderCloseDateFields = () => {
    return editVM.surveys.map((e, idx) => renderCloseDateField(e, idx))
  }

  const renderCloseDateField = (e, idx) => {
    const pickerClass =
      editVM.publishTried && !editVM.surveyDates[idx].sendDate
        ? 'pulse-survey-date-picker sch-error'
        : 'pulse-survey-date-picker'
    return (
      <div key={'pulse' + idx}>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          disabled={true}
          variant='inline'
          inputVariant='standard'
          format='MM/dd/yyyy'
          invalidDateMessage={null}
          margin='none'
          InputAdornmentProps={{ position: 'start' }}
          InputProps={{
            disableUnderline: true,
          }}
          value={editVM.surveyDates[idx].closeDate}
          onChange={(e) => editVM.setDateManually(e, idx)}
          className={pickerClass}
        />
      </div>
    )
  }

  const renderSurveyNumbers = () => {
    return editVM.surveys.map((e, idx) => renderSurveyNumber(e, idx))
  }

  const renderSurveyNumber = (e, idx) => {
    return (
      <div key={'num' + idx} className='survey-number-cont'>
        <Grid item xs className='survey-number-item'>
          <span key={`survey-${e.id}`} className='survey-selector-survey-btn'>
            {`${idx + 1}.`}
          </span>
        </Grid>
      </div>
    )
  }

  const pulses = editVM.surveys.map((content, index) => (
    <PulseSurveyCard
      key={`item-${index}`}
      index={index}
      survey={content}
      disabled={!editVM.surveys[index].isEditable}
      error={editVM.publishTried && !editVM.surveys[index].name}
      hideDragandDrop={editVM.campaignStatus === 'initiated'}
    />
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (editVM.surveysSent) return
    editVM.setOldIndex(oldIndex)
    editVM.setNewIndex(newIndex)
    editVM.movePulseItem()
  }

  const renderPulseCards = () => {
    return (
      <SortableList
        disabled={editVM.surveysSent}
        distance={3}
        items={pulses}
        onSortEnd={onSortEnd}
        shouldCancelStart={() => editVM.surveysSent}
      />
    )
  }

  const renderAutoClosePeriod = () => {
    if (!editVM.autoCloseEnabled) return null

    const renderCustomPeriodRow = (autoClose: AutoCloseVM) => {
      return (
        <div className='reminder-row' key={'autoclose-'}>
          <div className='reminder-input-row'>
            <TextField
              value={autoClose.value}
              onChange={(e) => autoClose.setValue(e.target.value)}
              variant='outlined'
              autoFocus
              onFocus={(e) => e.target.select()}
              disabled={!editVM.autoCloseEnabled}
            />
            <FormControl variant='outlined'>
              <Select
                value={autoClose.period}
                onChange={(e) => {
                  autoClose.setPeriod(e.target.value)
                }}
                className='reminder-period-select'
                disabled={!editVM.autoCloseEnabled}
              >
                <MenuItem value={'day'}>Days</MenuItem>
                <MenuItem value={'week'}>Weeks</MenuItem>
                <MenuItem value={'month'}>Months</MenuItem>
              </Select>
            </FormControl>
            <Typography className='reminder-text' component='span'>{`${
              lz.after_the
            } ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.is_sent}`}</Typography>
          </div>
        </div>
      )
    }

    return <>{renderCustomPeriodRow(editVM.autoClose)}</>
  }

  const renderAutoCloseToggle = () => {
    return (
      <div>
        <Tooltip
          title={`${lz.the_close_date_for} ${labelsStore.getLabelByLanguageAndFor('pulses')} ${
            lz.defined_by_period
          } ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.closes}`}
          placement='bottom-start'
        >
          <Typography className='schedule-text-secondary schedule-text-margin'>
            {lz.auto_close_toggle}
          </Typography>
        </Tooltip>
        <div className='section-content'>
          <FormControlLabel
            control={
              <Switch checked={editVM.autoCloseEnabled} onChange={() => editVM.toggleAutoClose()} />
            }
            label={editVM.autoCloseEnabled ? 'On' : 'Off'}
          />
          {renderAutoClosePeriod()}
        </div>
      </div>
    )
  }

  const renderDeleteIcon = (mode) => {
    if (mode === 'period' && editVM.periodReminders.length <= 1) return null
    return (
      <IconButton disabled={!editVM.sendReminders} onClick={() => editVM.deleteReminder(mode)}>
        <DeleteIcon />
      </IconButton>
    )
  }

  const renderCustomPeriod = () => {
    if (!editVM.sendReminders) return null

    const renderCustomPeriodRow = (reminder: ReminderVM, index: number) => {
      return (
        <div className='reminder-row' key={'remind-' + index}>
          <div className='reminder-input-row'>
            {reminder.period === 'minute' ? (
              <FormControl variant='outlined'>
                <Select
                  value={reminder.value}
                  onChange={(e) => {
                    reminder.setValue(e.target.value)
                  }}
                  className='reminder-value-select'
                  disabled={!editVM.sendReminders}
                >
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TextField
                value={reminder.value}
                onChange={(e) => reminder.setValue(e.target.value as string)}
                variant='outlined'
                autoFocus
                onFocus={(e) => e.target.select()}
                disabled={!editVM.sendReminders}
              />
            )}
            <FormControl variant='outlined'>
              <Select
                value={reminder.period}
                onChange={(e) => {
                  reminder.setPeriod(e.target.value)
                }}
                className='reminder-period-select'
                disabled={!editVM.sendReminders}
              >
                <MenuItem value={'hour'}>Hours</MenuItem>
                <MenuItem value={'minute'}>Minutes</MenuItem>
                <MenuItem value={'day'}>Days</MenuItem>
                <MenuItem value={'week'}>Weeks</MenuItem>
                <MenuItem value={'month'}>Months</MenuItem>
              </Select>
            </FormControl>
            <Typography className='reminder-text' component='span'>{`${
              lz.before_the
            } ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.closes}`}</Typography>
            {renderDeleteIcon(reminder.mode)}
            <IconButton
              onClick={() => editVM.addReminder({ mode: 'period', units: 15, period: 'minute' })}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )
    }

    return (
      <>{editVM.periodReminders.map((reminder, index) => renderCustomPeriodRow(reminder, index))}</>
    )
  }

  const renderRemindersToggle = () => {
    if (!editVM.autoCloseEnabled) return null
    return (
      <div>
        <Typography className='schedule-text-secondary schedule-text-margin'>
          {lz.send_automatic_reminders}
        </Typography>
        <div className='section-content'>
          <FormControlLabel
            control={
              <Switch
                checked={editVM.sendReminders}
                onChange={() => editVM.toggleSendReminders()}
              />
            }
            label={editVM.sendReminders ? 'On' : 'Off'}
          />
          {renderCustomPeriod()}
        </div>
      </div>
    )
  }

  return (
    <Grid id='ScheduleStep'>
      <Grid item xs={4} className='schedule-section'>
        {renderStartDate()}
        {/* {renderEndDate()} */}
        {renderSendTime()}
        {renderTimezone()}
        {renderIncludedDays()}
        {renderAutoCloseToggle()}
        {renderRemindersToggle()}
      </Grid>
      <Grid item xs={8} className='schedule-section'>
        {renderSendSchedule()}
      </Grid>
    </Grid>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore',
  'labelsStore'
)(observer(ScheduleStep))
