import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Group } from '../aggregate/Group'
import GroupBasicInfoVM from './GroupBasicInfoVM'
import { GroupsUpdateService } from '../service/GroupsUpdateService'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'

export class GroupEditVM {
  private rootStore: RootStore
  private svc: GroupsUpdateService
  private group: Group
  private existingUsers: string[] = []

  constructor(rootStore: RootStore, group?: Group) {
    this.rootStore = rootStore
    if (group) {
      this.loadData(group)
    }
    if (!group) {
      this.group = Group.create(this.rootStore.appStore.currentOrgId)
    }
    this.basicInfo = new GroupBasicInfoVM(this.rootStore, group)
    this.svc = new GroupsUpdateService(this.rootStore)
    this.participantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      false,
      false,
      false,
      true,
      false
    )
  }

  private loadData(group: Group) {
    this.group = group
    this.name = group.name
    this.objectId = group.objectId
    this.isDeleted = group.isDeleted
    this.isProcessing = group.isProcessing
    this.organizationId = group.organizationId
    if (group.privilegeSets) this.selectedPrivilegeSets = group.privilegeSets
    this.description = group.description
  }

  public participantsSelectVM: ParticipantsSelectVM
  @observable public basicInfo: GroupBasicInfoVM = null
  @observable public isDeleted: boolean = false
  @observable public isProcessing: boolean = false
  @observable public isMembersLoaded: boolean = false
  @observable public isLoading: boolean = false
  @observable public objectId: string = ''
  @observable public organizationId: string = ''
  @observable public name: string = ''
  @observable public description: string = ''
  @observable public selectedPrivilegeSets: Array<any> = []
  @observable public saveTried: boolean = false
  @observable public tabValue: number = 0
  @observable public isParticipantsTabOpen: boolean = false
  @observable public assignedToAudienceMembers: IParticipant[] = []
  @observable public snackbarMessage: string = ''
  @observable public isSnackbarOpen: boolean = false

  private async loadExistingUsers() {
    if (this.isNew) return
    this.isLoading = true
    this.isMembersLoaded = true
    this.participantsSelectVM.addParticipant(this.group.objectId, this.group.name, 'group')
    await this.participantsSelectVM.explodeParticipant(this.group.objectId)
    this.participantsSelectVM.setVisible()
    this.existingUsers = this.participantsSelectVM.participants.map((e) => e.id)
    this.isLoading = false
  }

  @computed
  public get isNew(): boolean {
    return !Boolean(this.group.objectId)
  }

  @computed
  public get groupNameErrorMessage(): string {
    if (this.basicInfo.nameHasPeriod) return 'Group name cannot include periods.'
    return ''
  }

  @computed
  public get saveDisabled(): boolean {
    if (this.isProcessing) return true
    if (this.basicInfo.nameHasPeriod) return true
    return false
  }

  @action
  public setTabValue(value) {
    if (!this.isMembersLoaded && value === 1) this.loadExistingUsers()
    this.tabValue = value
  }

  @action
  public toggleParticipantSelectOpen() {
    this.isParticipantsTabOpen = !this.isParticipantsTabOpen
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get isNameValid() {
    if (!this.saveTried) return true
    return this.basicInfo.name && this.basicInfo.name.trim() !== ''
  }

  public getMembersToRemove(): string[] {
    const finalUsers = this.participantsSelectVM.participants.map((e) => e.id)
    return this.existingUsers.filter((e) => !finalUsers.includes(e))
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    return this.isNameValid
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return
    try {
      this.isProcessing = true
      const newGroup = {
        objectId: this.objectId ? this.objectId : '',
        name: this.basicInfo.name.trim(),
        description: this.basicInfo.description,
        privilegeSets: this.basicInfo.privilegeSetIds,
        defaultDashboardId: this.basicInfo.defaultDashboardId,
        forceDashboardRoute: this.basicInfo.forceDashboardRoute,
        isDeleted: false,
        organizationId: this.rootStore.appStore.currentOrgId,
      }
      const savedGroup = await this.svc.saveGroup(this.rootStore.appStore.currentOrgId, newGroup)
      const userIdsToRemove = this.getMembersToRemove()
      const userIdsToAdd = this.participantsSelectVM.participants
        .filter((e) => !this.existingUsers.includes(e.id))
        .map((e) => e.id)
      await this.svc.updateGroupMembers(
        this.rootStore.appStore.currentOrgId,
        savedGroup.objectId,
        userIdsToAdd,
        userIdsToRemove
      )
      this.isProcessing = false
      this.rootStore.groupsStore.toggleGroupsDrawer()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.isLoading = true
      await this.svc.deleteGroup(this.rootStore.appStore.currentOrgId, this.group.objectId)
      this.rootStore.groupsStore.toggleGroupsDrawer()
    } catch (e) {
      console.error(e)
      this.snackbarMessage = 'Error: ' + e.message
      this.isSnackbarOpen = true
    } finally {
      this.isLoading = false
    }
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  public async refreshEvents() {
    await this.svc.refreshEvents(this.objectId)
  }
}
