import { action, computed, observable } from 'mobx'
import { Category } from '../aggregate/Category'
import { ParseService } from '../../services/ParseService'
import rootStore from '../../stores/RootStore'
import { CategoryPickerVM } from '../../../app/shared/category-picker/CategoryPickerVM'

export default class CategoryWidgetForm {
  public categoryPickerVM: CategoryPickerVM

  constructor() {
    this.categoryPickerVM = new CategoryPickerVM(rootStore)
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable parentCategoryId: string = ''
  @observable parentCategoryLabel: string = ''
  @observable availableForSkills: boolean = false
  @observable availableForSurveys: boolean = false
  @observable availableForTasks: boolean = false
  @observable availableForWork: boolean = false
  @observable showCantDeleteDialog: boolean = false
  @observable addNew: boolean = false
  private categories = []
  @observable isFormDrawerOpen: boolean = false
  @observable isSaving: boolean = false
  @observable saveSuccessful: boolean = false
  @observable addingNewCategory = false
  @observable editingExistingCategory = false
  @observable allSubordinateCategories: Array<Category> = []
  @observable allSubordinateCategoryIds: Array<string> = []
  @observable organizationId: string = ''
  @observable isDeleted: boolean = false

  @action initAddForm(categories: Category[]) {
    this.categories = categories
    this.organizationId = rootStore.appStore.currentOrgId
    this.objectId = ''
    this.name = ''
    this.description = ''
    this.parentCategoryId = ''
    this.parentCategoryLabel = ''
    this.availableForSkills = false
    this.availableForSurveys = false
    this.availableForTasks = false
    this.availableForWork = false
    this.isSaving = false
    this.saveSuccessful = false
    this.editingExistingCategory = false
    this.addingNewCategory = true
    this.isDeleted = false
    this.categoryPickerVM = new CategoryPickerVM(rootStore)
  }

  @action initEditForm(categories: Category[], categoryObjectId) {
    const category = categories.filter((category) => category.objectId === categoryObjectId)[0]
    const parentCategory = categories.filter((cat) => category.parentCategoryId === cat.objectId)[0]

    this.categories = categories
    this.organizationId = category.organizationId
    this.name = category.name
    this.description = category.description
    this.parentCategoryId = category.parentCategoryId
    this.parentCategoryLabel = Boolean(parentCategory) ? parentCategory.name : ''
    this.availableForSkills = category.availableForSkills
    this.availableForSurveys = category.availableForSurveys
    this.availableForTasks = category.availableForTasks
    this.availableForWork = category.availableForWork
    this.isDeleted = category.isDeleted
    this.isSaving = false
    this.saveSuccessful = false
    this.addingNewCategory = false
    this.editingExistingCategory = true
    this.allSubordinateCategories = category.getAllSubordinateCategories()
    this.allSubordinateCategoryIds = this.allSubordinateCategories.map((cat) => cat.objectId)
    this.categoryPickerVM.setAppliedCategory(category.parentCategoryId)
    const categoryIdsToHide = [category.objectId, ...this.allSubordinateCategoryIds]
    this.categoryPickerVM.setCategoryIdsToHide(categoryIdsToHide)
  }

  @action openDrawer() {
    this.isFormDrawerOpen = true
  }

  @action closeDrawer() {
    this.isFormDrawerOpen = false
  }

  @action setField(key: string, value: string | boolean) {
    this[key] = value
    this.isSaving = false
  }

  @action setSavePending() {
    this.isSaving = true
  }

  @action setSaveResolved() {
    this.isSaving = false
  }

  @action setSaveSuccessful() {
    this.saveSuccessful = true
  }

  @computed get isNameValid() {
    if (this.isSaving && !this.name) return false
    return true
  }

  @computed get saveEnabled() {
    return !this.isSaving && this.isNameValid
  }

  //Data formatting for Parse Service functions
  //Function to prep the form data to save to Parse
  public toJsonObject() {
    //Don't pass UI state variables to Parse
    const keysToNotSave = [
      'isFormDrawerOpen',
      'isSaving',
      'saveSuccessful',
      'categories',
      'addingNewCategory',
      'editingExistingCategory',
      'parentCategoryLabel',
      'categoryPickerVM',
    ]
    const jsonObject = { ...this }
    keysToNotSave.map((key) => delete jsonObject[key])
    const formattedObject = this.formatStringFields(jsonObject)
    return formattedObject
  }

  private formatStringFields(object: Object) {
    const entries = Object.entries(object)
    for (const [key, value] of entries) {
      //Trim trailing whitespace from string values
      if (typeof value === 'string') object[key] = value.trim()
    }
    return object
  }

  @action
  public toggleCantDeleteDialog() {
    this.showCantDeleteDialog = !this.showCantDeleteDialog
  }

  @computed
  public get connectedRoles() {
    const weightProfiles = rootStore.weightProfilesStore.currentOrgWeightProfiles
    let connectedProfiles = weightProfiles.filter((e) =>
      e.categories.find((f) => f.categoryId === this.objectId && f.isChecked)
    )

    let connectedRoles = []

    connectedProfiles.forEach((profile) => {
      const foundRole = rootStore.rolesStore.getRole(profile.roleId)
      if (foundRole) connectedRoles.push(foundRole)
    })

    return connectedRoles
  }

  @action
  public async delete() {
    try {
      if (this.connectedRoles.length) {
        this.toggleCantDeleteDialog()
      } else {
        await new ParseService().deleteCategory(rootStore.appStore.currentOrgId, this.objectId)
        this.closeDrawer()
      }
    } catch (e) {
      console.error(e)
    }
  }
}
