import { RootStore } from 'src/app/stores/RootStore'
import { ActionableInsightsService } from '../service/ActionableInsightsService'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import rootStore from '../../../stores/RootStore'
import { IActionableInsightsPeriod } from '../interfaces/IActionableInsightsPeriod'
import { IActionableInsightsQuickStats } from '../interfaces/IActionableInsightsQuickStats'
import { IActionableInsightsGroup } from '../interfaces/IActionableInsightsGroup'
import { IActionableInsightsInsights } from '../interfaces/IActionableInsightsInsights'
import { InsightVM } from './InsightVM'

export class ActionableInsightsVM {
  private rootStore: RootStore
  private orgId: string
  private userId: string
  private reactions: IReactionDisposer[] = []

  constructor() {
    this.rootStore = rootStore
    this.orgId = rootStore.appStore.currentOrgId
    this.userId = rootStore.appStore.currentUserId
    this.checkAccess()
    this.initializeReport()
  }

  @observable private isLoading = false
  @observable private isLoaded = false
  @observable public selectedPeriodId: number = undefined
  @observable public selectedGroupId: number = undefined
  @observable public insightPeriodAnchorEl: any = null
  @observable public groupAnchorEl: any = null
  @observable public periods: IActionableInsightsPeriod[] = []
  @observable public groups: IActionableInsightsGroup[] = []
  @observable public quickStats: IActionableInsightsQuickStats = undefined
  @observable public insights: InsightVM[] = []

  @action
  private loadReactions() {
    this.reactions.push(
      reaction(
        () => this.selectedPeriodId,
        async () => {
          this.isLoaded = false
          this.isLoading = true
          await this.fetchGroups(this.orgId)
          await this.fetchInsights(this.orgId)
          await this.fetchQuickStats(this.orgId)
          this.isLoaded = true
          this.isLoading = false
        }
      )
    )

    this.reactions.push(
      reaction(
        () => this.selectedGroupId,
        async () => {
          this.isLoaded = false
          this.isLoading = true
          await this.fetchPeriods(this.orgId)
          this.initializeFirstPeriod()
          await this.fetchInsights(this.orgId)
          await this.fetchQuickStats(this.orgId)
          this.isLoaded = true
          this.isLoading = false
        }
      )
    )
  }

  public dispose() {
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
  }

  @action
  private setPeriods(periods: IActionableInsightsPeriod[]) {
    this.periods = periods
  }

  @action
  private setGroups(groups: IActionableInsightsGroup[]) {
    this.groups = groups
  }

  @action
  private setInsights(insights: IActionableInsightsInsights[]) {
    this.insights = insights.map((insight) => new InsightVM(insight))
  }

  @action
  private setQuickStats(quickStats: IActionableInsightsQuickStats) {
    this.quickStats = quickStats
  }

  private async fetchPeriods(organizationId: string) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const svc = new ActionableInsightsService()
    const req = {
      organizationId: organizationId,
      userId: this.userId,
      group: this.selectedGroup,
    }
    try {
      const result = await svc.getPeriods(req, token)
      if (result.success) {
        this.setPeriods(result.periods)
      } else {
        console.error(result.errorMessage)
      }
      if (result.periods.length === 0) {
        this.isLoaded = true
        this.isLoading = false
      }
    } catch (error) {
      console.error('Failed to fetch actionable insights periods', error)
    }
  }

  private async fetchGroups(organizationId: string) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const svc = new ActionableInsightsService()
    const req = {
      organizationId: organizationId,
      OrgPeriodPK: this.selectedPeriodId,
      userId: this.userId,
    }
    try {
      const result = await svc.getGroups(req, token)
      if (result.success) {
        this.setGroups(result.groups)
      } else {
        console.error(result.errorMessage)
      }
    } catch (error) {
      console.error('Failed to fetch actionable insights groups', error)
    }
  }

  private async fetchQuickStats(organizationId: string) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const svc = new ActionableInsightsService()
    this.isLoading = true
    const req = {
      organizationId: organizationId,
      period: this.selectedPeriod,
      group: this.selectedGroup,
      userId: this.userId,
    }
    try {
      const result = await svc.getQuickStats(req, token)
      if (result.success) {
        this.setQuickStats(result.quickStats)
      } else {
        console.error(result.errorMessage)
      }
    } catch (error) {
      console.error('Failed to fetch actionable insights quick stats', error)
    }
  }

  private async fetchInsights(organizationId: string) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const svc = new ActionableInsightsService()
    this.isLoading = true
    const req = {
      organizationId: organizationId,
      period: this.selectedPeriod,
      group: this.selectedGroup,
      userId: this.userId,
    }
    try {
      const result = await svc.getInsights(req, token)
      if (result.success) {
        this.setInsights(result.insights)
      } else {
        console.error(result.errorMessage)
      }
    } catch (error) {
      console.error('Failed to fetch insights', error)
    }
  }

  private async initializeReport() {
    this.isLoaded = false
    this.isLoading = true
    await this.fetchPeriods(this.orgId)
    this.initializeFirstPeriod()
    await this.fetchGroups(this.orgId)
    this.loadReactions()
    this.initializeFirstGroup()
  }

  private initializeFirstPeriod() {
    if (this.periods && this.periods.length > 0) {
      this.selectedPeriodId = this.periods[0].OrgPeriodPk
    }
  }

  private initializeFirstGroup(attempts: number = 0) {
    if (attempts === 12) return
    if (!this.groups || this.groups.length === 0) {
      setTimeout(() => this.initializeFirstGroup(++attempts), 500)
      return
    }
    if (this.groups && this.groups.length > 0) {
      this.selectedGroupId = this.groups[0].GrpPk
    }
  }

  public async checkAccess(attempts: number = 0) {
    if (attempts === 14) return
    if (!rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.checkAccess(++attempts), 500)
      return
    }
    const canAccess = rootStore.appStore.canAccessActionableInsights
    const isEnabled = rootStore.organizationsStore.currentOrganization.isActionableInsightsEnabled
    if (!canAccess || !isEnabled) {
      rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    }
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore?.appStore?.canAccessActionableInsights) return false
    if (!this.rootStore?.organizationsStore?.currentOrganization?.isActionableInsightsEnabled)
      return false
    return true
  }

  @computed
  public get spinnerShown(): boolean {
    return this.isLoading && !this.isLoaded
  }

  @computed
  private get hasData(): boolean {
    return this.quickStats && Object.keys(this.quickStats).length > 0
  }

  @computed
  private get hasPeriods(): boolean {
    return this.periods && this.periods.length > 0
  }

  @computed
  public get emptyStateMessageShown(): boolean {
    return !this.isLoading && this.isLoaded && !this.hasPeriods
  }

  @action
  public setSelectedPeriod(periodId: number) {
    this.selectedPeriodId = periodId
    this.closeInsightPeriodMenu()
  }

  @action
  public setSelectedGroup(groupId: number) {
    this.selectedGroupId = groupId
    this.closeGroupMenu()
  }

  @computed
  private get selectedPeriod(): IActionableInsightsPeriod {
    if (!this.periods) return undefined
    return this.periods.find((e) => e.OrgPeriodPk === this.selectedPeriodId)
  }

  @computed
  public get selectedGroup(): IActionableInsightsGroup {
    if (!this.groups) return undefined
    return this.groups.find((e) => e.GrpPk === this.selectedGroupId)
  }

  @computed
  public get selectedPeriodName(): string {
    if (!this.selectedPeriod) return ''
    return `Pulses ${this.selectedPeriod.PeriodRange}`
  }

  @computed
  public get selectedGroupName(): string {
    if (!this.selectedGroup) return ''
    return this.selectedGroup.GroupName
  }

  @computed
  public get insightPeriodDates(): string {
    if (!this.selectedPeriod) return ''
    return this.formatDateRange(this.selectedPeriod.DateRange)
  }

  private formatDateRange(dateRange: string): string {
    return dateRange
      .split(' - ')
      .map((date) => {
        const parts = date.split(' ')
        if (parts.length === 3 && !parts[1].endsWith(',')) {
          parts[1] += ','
        }
        return parts.join(' ')
      })
      .join(' - ')
  }

  @computed
  public get insightPeriodMenuShown(): boolean {
    return this.insightPeriodAnchorEl !== null
  }

  @action
  public openInsightPeriodMenu(e: any) {
    this.insightPeriodAnchorEl = e
  }

  @action
  public closeInsightPeriodMenu() {
    this.insightPeriodAnchorEl = null
  }

  @computed
  public get groupMenuShown(): boolean {
    return this.groupAnchorEl !== null
  }

  @action
  public openGroupMenu(e: any) {
    this.groupAnchorEl = e
  }

  @action
  public closeGroupMenu() {
    this.groupAnchorEl = null
  }

  @computed
  public get directive(): string {
    if (this.quickStats && this.quickStats.Directive) return this.quickStats.Directive
    return '--'
  }

  @computed
  public get sentimentScore(): string {
    if (this.quickStats && this.quickStats.SentimentScore) return this.quickStats.SentimentScore
    return '--'
  }

  @computed
  public get responseRate(): string {
    if (this.quickStats && this.quickStats.RespRate) return this.quickStats.RespRate
    return '--'
  }

  @computed
  public get motivationScore(): string {
    if (this.quickStats && this.quickStats.MotivScore) return this.quickStats.MotivScore
    return '--'
  }

  @computed
  public get motivationLabel(): string {
    if (this.quickStats && this.quickStats.MotivLabel) return this.quickStats.MotivLabel
    return ''
  }

  @computed
  public get workloadScore(): string {
    if (this.quickStats && this.quickStats.WorkScore) return this.quickStats.WorkScore
    return '--'
  }

  @computed
  public get workloadLabel(): string {
    if (this.quickStats && this.quickStats.WorkLabel) return this.quickStats.WorkLabel
    return ''
  }

  @computed
  public get sentTrendDirection(): string {
    if (this.quickStats && this.quickStats.SentTrendDirection)
      return this.quickStats.SentTrendDirection
    return 'None'
  }

  @computed
  public get sentTrendValue(): string {
    if (this.quickStats && this.quickStats.SentTrendValue) return this.quickStats.SentTrendValue
    return '-'
  }

  @computed
  public get sentTrendColor(): string {
    if (this.quickStats && this.quickStats.SentTrendColor) return this.quickStats.SentTrendColor
    return 'None'
  }

  @computed
  public get respTrendDirection(): string {
    if (this.quickStats && this.quickStats.RespTrendDirection)
      return this.quickStats.RespTrendDirection
    return 'None'
  }

  @computed
  public get respTrendValue(): string {
    if (this.quickStats && this.quickStats.RespTrendValue) return this.quickStats.RespTrendValue
    return '-'
  }

  @computed
  public get respTrendColor(): string {
    if (this.quickStats && this.quickStats.RespTrendColor) return this.quickStats.RespTrendColor
    return 'None'
  }

  @computed
  public get motivTrendDirection(): string {
    if (this.quickStats && this.quickStats.MotivTrendDirection)
      return this.quickStats.MotivTrendDirection
    return 'None'
  }

  @computed
  public get motivTrendValue(): string {
    if (this.quickStats && this.quickStats.MotivTrendValue) return this.quickStats.MotivTrendValue
    return '-'
  }

  @computed
  public get motivTrendColor(): string {
    if (this.quickStats && this.quickStats.MotivTrendColor) return this.quickStats.MotivTrendColor
    return 'None'
  }

  @computed
  public get workTrendDirection(): string {
    if (this.quickStats && this.quickStats.WorkTrendDirection)
      return this.quickStats.WorkTrendDirection
    return 'None'
  }

  @computed
  public get workTrendValue(): string {
    if (this.quickStats && this.quickStats.WorkTrendValue) return this.quickStats.WorkTrendValue
    return '-'
  }

  @computed
  public get workTrendColor(): string {
    if (this.quickStats && this.quickStats.WorkTrendColor) return this.quickStats.WorkTrendColor
    return 'None'
  }

  @computed
  public get progressionDataAvailable(): boolean {
    if (
      this.quickStats &&
      this.quickStats.WorkTrendValue &&
      this.quickStats.WorkTrendValue !== '-' &&
      this.quickStats.MotivTrendValue &&
      this.quickStats.MotivTrendValue !== '-' &&
      this.quickStats.SentTrendValue &&
      this.quickStats.SentTrendValue !== '-' &&
      this.quickStats.RespTrendValue &&
      this.quickStats.RespTrendValue !== '-'
    )
      return true
    return false
  }

  public get dataUnavailableMessage(): string {
    return 'There is not enough data to produce actionable insights for this period.'
  }
}
