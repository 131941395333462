import { IActionableInsightsInsights } from '../interfaces/IActionableInsightsInsights'

export class InsightVM {
  constructor(insight: IActionableInsightsInsights = null) {
    if (insight) this.loadData(insight)
  }

  private loadData(insight: IActionableInsightsInsights) {
    this.Directive = insight.Directive
    this.Frame = insight.Frame
    this.Insight = insight.Insight
    this.SentimentScore = insight.SentimentScore
  }

  public Directive: string = ''
  public Frame: string = ''
  public Insight: string = ''
  public SentimentScore: number = undefined
}
